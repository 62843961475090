import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

class OrdersTable extends Component {
  setOrderStatusColor = (situation_id) => {
    const orange = [9];
    const rose = [2, 3, 6, 7, 16];
    const green = [4];
    const red = [8];
    const light_blue = [13, 15, 17];
    const dark_blue = [11];
    const grey = [14];
    
    if (orange.includes(situation_id)) return { backgroundColor: '#FAF5ED' }
    if (rose.includes(situation_id)) return { backgroundColor: '#FEF8F8' }
    if (green.includes(situation_id)) return { backgroundColor: '#F1F3EA' }
    if (red.includes(situation_id)) return { backgroundColor: '#F7EAE8' }
    if (light_blue.includes(situation_id)) return { backgroundColor: '#F6FBFC' }
    if (dark_blue.includes(situation_id)) return { backgroundColor: '#ECF1F7' }
    if (grey.includes(situation_id)) return { backgroundColor: '#F1F1F1' }
  }

  render() {
    const iconOrder = this.props.sort.order === 'asc' ? (<span className="mdi mdi-menu-up font-weight-bolder"></span>) : (<span className="mdi mdi-menu-down font-weight-bolder"></span>);
    let tbodyContent = (<tr></tr>);

    if (!this.props.loading) {
      tbodyContent = (
        this.props.ordersEntries.map((row) => (
          <tr key={row.id} style={this.setOrderStatusColor(row.situacao_id)}>
            <th className="align-middle" scope="row">{row.id}</th>
            <td className="align-middle" align="center">
              <div className="py-1 w-100" style={{ border: '1px solid #dee2e6', borderRadius: '12px' }}>
                {row.situacao}
              </div>
            </td>
            <td className="align-middle">{row.data_criacao}</td>
            <td className="align-middle">{row.cliente.nome.length > 27 ? `${row.cliente.nome.substr(0, 27)}...` : row.cliente.nome}</td>
            <td className="align-middle">{row.cliente.enderecos[0].estado}</td>
            <td className="align-middle">{row.pagamento === null ? '' : row.pagamento.forma_pagamento_nome}</td>
            <td className="align-middle">{row.envio === null ? '' : row.envio.forma_envio_tipo}</td>
            <td className="align-middle"><strong>{row.valor_total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong></td>
            <td><button type="button" className="btn btn-primary btn-sm" onClick={() => this.props.showDetailedOrder(row.id)}><i className="mdi mdi-eye"></i></button></td>
          </tr>
        ))
      );
    }

    return (
      <Table className="mb-0" hover responsive>
        <thead>
          <tr>
            <th className="align-middle" height="60" onClick={() => this.props.sortColumn('id')} style={{ cursor: 'pointer' }}>
              #
              {this.props.sort.column_sort === 'id' ? iconOrder : (<span style={{ color: '#BBB' }}><i className="mdi mdi-menu-up font-weight-bolder"></i><i className="mdi mdi-menu-down font-weight-bolder"></i></span>)}
            </th>
            <th className="align-middle" height="60" onClick={() => this.props.sortColumn('situacao')} style={{ cursor: 'pointer' }}>
              Status
              {this.props.sort.column_sort === 'situacao' ? iconOrder : (<span style={{ color: '#BBB' }}><i className="mdi mdi-menu-up font-weight-bolder"></i><i className="mdi mdi-menu-down font-weight-bolder"></i></span>)}
            </th>
            <th className="align-middle" height="60" onClick={() => this.props.sortColumn('data_criacao')} style={{ cursor: 'pointer' }}>
              Data
              {this.props.sort.column_sort === 'data_criacao' ? iconOrder : (<span style={{ color: '#BBB' }}><i className="mdi mdi-menu-up font-weight-bolder"></i><i className="mdi mdi-menu-down font-weight-bolder"></i></span>)}
            </th>
            <th className="align-middle" height="60" onClick={() => this.props.sortColumn('clientes.nome')} style={{ cursor: 'pointer' }}>
              Cliente
              {this.props.sort.column_sort === 'clientes.nome' ? iconOrder : (<span style={{ color: '#BBB' }}><i className="mdi mdi-menu-up font-weight-bolder"></i><i className="mdi mdi-menu-down font-weight-bolder"></i></span>)}
            </th>
            <th className="align-middle" height="60" onClick={() => this.props.sortColumn('cliente_enderecos.estado')} style={{ cursor: 'pointer' }}>
              UF
              {this.props.sort.column_sort === 'cliente_enderecos.estado' ? iconOrder : (<span style={{ color: '#BBB' }}><i className="mdi mdi-menu-up font-weight-bolder"></i><i className="mdi mdi-menu-down font-weight-bolder"></i></span>)}
            </th>
            <th className="align-middle" height="60" onClick={() => this.props.sortColumn('pedido_pagamentos.forma_pagamento_nome')} style={{ cursor: 'pointer' }}>
              Pagamento
              {this.props.sort.column_sort === 'pedido_pagamentos.forma_pagamento_nome' ? iconOrder : (<span style={{ color: '#BBB' }}><i className="mdi mdi-menu-up font-weight-bolder"></i><i className="mdi mdi-menu-down font-weight-bolder"></i></span>)}
            </th>
            <th className="align-middle" height="60" onClick={() => this.props.sortColumn('pedido_envios.forma_envio_tipo')} style={{ cursor: 'pointer' }}>
              Envio
              {this.props.sort.column_sort === 'pedido_envios.forma_envio_tipo' ? iconOrder : (<span style={{ color: '#BBB' }}><i className="mdi mdi-menu-up font-weight-bolder"></i><i className="mdi mdi-menu-down font-weight-bolder"></i></span>)}
            </th>
            <th className="align-middle" height="60" onClick={() => this.props.sortColumn('valor_total')} style={{ cursor: 'pointer' }}>
              Total
              {this.props.sort.column_sort === 'valor_total' ? iconOrder : (<span style={{ color: '#BBB' }}><i className="mdi mdi-menu-up font-weight-bolder"></i><i className="mdi mdi-menu-down font-weight-bolder"></i></span>)}
            </th>
            <th className="align-middle" height="60"></th>
          </tr>
        </thead>
        <tbody>
          {tbodyContent}
        </tbody>
      </Table>
    );
  }
}

export default OrdersTable;