import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import '../../../../assets/css/custom-daterangepicker.css';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../../../../assets/css/datatables.css';

import AUX from '../../../../hoc/Aux_';
import { doGet } from '../../../../helpers/ApiHelper';
import * as actionTypes from '../../../../store/action';
import DetailedProduct from './DetailedProduct';

class SelledProducts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 'selled_products',
      productId: null,
      loading: true,
      productsTable: {
        summary: {
          countSold: 0,
          countReserved: 0,
          unitaryCountSold: 0,
          unitaryCountReserved: 0,
          billingSold: 0,
          billingReserved: 0,
          avgDiscountSold: 0,
          avgDiscountReserved: 0,
          shippingPaidSold: 0,
          shippingPaidReserved: 0,
          countFreeShippingSold: 0,
          countFreeShippingReserved: 0,
          percentageOfTotalFreeShippingSold: 0,
          percentageOfTotalFreeShippingReserved: 0,
          countCouponSold: 0,
          countCouponReserved: 0,
          percentageOfCouponSold: 0,
          percentageOfCouponReserved: 0
        },
        entries: []
      },
      filterProducts: 'all_products',
      startDate: this.props.location.startDate === undefined ? moment().format('YYYY-MM-DD') : moment(this.props.location.startDate).format('YYYY-MM-DD'),
      endDate: this.props.location.endDate === undefined ? moment().format('YYYY-MM-DD') : moment(this.props.location.endDate).format('YYYY-MM-DD'),
      initialValue: (this.props.location.startDate === undefined && this.props.location.endDate === undefined) ? `${moment().format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}` : `${moment(this.props.location.startDate).format('DD/MM/YYYY')} - ${moment(this.props.location.endDate).format('DD/MM/YYYY')}`
    };
  }

  componentDidMount() {
    this.loadSelledProducts();
  }

  changeCurrentTab(tab) {
    window.scrollTo(0, 0);
    this.setState({ currentTab: tab });
  }

  loadSelledProducts = (filters = true) => {
    let params = '';

    if (filters === true) {
      const start_date = moment(this.state.startDate).format('YYYY-MM-DD');
      const end_date = moment(this.state.endDate).format('YYYY-MM-DD');
      let filter_products = '';
      if (this.state.filterProducts !== 'all_products') 
        filter_products = this.state.filterProducts === 'only_unitary' ? '&only_unitary=true' : '&only_kit=true';

      params = `?start_date=${start_date}&end_date=${end_date}${filter_products}`;
    }

    this.setState({
      loading: true,
      productsTable: {
        summary: { countSold: 0, countReserved: 0, unitaryCountSold: 0, unitaryCountReserved: 0, billingSold: 0, billingReserved: 0, avgDiscountSold: 0, avgDiscountReserved: 0, shippingPaidSold: 0, shippingPaidReserved: 0, countFreeShippingSold: 0, countFreeShippingReserved: 0, percentageOfTotalFreeShippingSold: 0, percentageOfTotalFreeShippingReserved: 0, countCouponSold: 0, countCouponReserved: 0, percentageOfCouponSold: 0, percentageOfCouponReserved: 0 },
        entries: []
      },
      filterProducts: filters === true ? this.state.filterProducts : 'all_products',
    }, () => {
      doGet(`/products/selled_products${params}`).then(response =>
        this.setState({
          loading: false,
          productsTable: {
            summary: {
              countSold: response['summary']['count_sold'],
              countReserved: response['summary']['count_reserved'],
              unitaryCountSold: response['summary']['unitary_count_sold'],
              unitaryCountReserved: response['summary']['unitary_count_reserved'],
              billingSold: response['summary']['billing_sold'],
              billingReserved: response['summary']['billing_reserved'],
              avgDiscountSold: response['summary']['avg_discount_sold'],
              avgDiscountReserved: response['summary']['avg_discount_reserved'],
              shippingPaidSold: response['summary']['shipping_paid_sold'],
              shippingPaidReserved: response['summary']['shipping_paid_reserved'],
              countFreeShippingSold: response['summary']['count_free_shipping_sold'],
              countFreeShippingReserved: response['summary']['count_free_shipping_reserved'],
              percentageOfTotalFreeShippingSold: response['summary']['percentage_of_total_free_shipping_sold'],
              percentageOfTotalFreeShippingReserved: response['summary']['percentage_of_total_free_shipping_reserved'],
              countCouponSold: response['summary']['count_coupon_sold'],
              countCouponReserved: response['summary']['count_coupon_reserved'],
              percentageOfCouponSold: response['summary']['percentage_of_coupon_sold'],
              percentageOfCouponReserved: response['summary']['percentage_of_coupon_reserved']
            },
            entries: response['entries']
          }
        })
      )
      .catch(() => {
        this.setState({
          loading: false,
          productsTable: {
            summary: { countSold: 0, countReserved: 0, unitaryCountSold: 0, unitaryCountReserved: 0, billingSold: 0, billingReserved: 0, avgDiscountSold: 0, avgDiscountReserved: 0, shippingPaidSold: 0, shippingPaidReserved: 0, countFreeShippingSold: 0, countFreeShippingReserved: 0, percentageOfTotalFreeShippingSold: 0, percentageOfTotalFreeShippingReserved: 0, countCouponSold: 0, countCouponReserved: 0, percentageOfCouponSold: 0, percentageOfCouponReserved: 0 },
            entries: []
          }
        });
      });
    });   
  }

  handleDateRangePickerCallback = (start, end) => {
    this.setState({
      startDate: start.format('YYYY-MM-DD'),
      endDate: end.format('YYYY-MM-DD')
    });
  }

  handleApply = (ev, picker) => {
    const interval = picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY');
    this.setState({ initialValue: interval })
    picker.element.val(interval);
    this.loadSelledProducts();
  }

  handleChangeFilterProducts = (event) => {
    this.setState({ filterProducts: event.target.value }, () => this.loadSelledProducts());
  }

  componentDidUpdate() {
    if (this.props.order_updated === true) {
      this.setState({
        optionSelected: { value: -1, label: `Hoje` },
        startDate: new Date(),
        endDate: new Date()
      }, () => { this.loadSelledProducts(); this.props.UpdateOrders(false); });
    }
  }

  render() {
    let productsContent = (<div className="mt-5 spinner"></div>);
    const dateRangePickerSettings = {
      autoUpdateInput: false,
      alwaysShowCalendars: true,
      showDropdowns: true,
      showCustomRangeLabel: false,
      opens: 'left',
      startDate: moment().toDate(),
      endDate: moment().toDate(),
      maxYear: parseInt(moment().format('Y')),
      ranges: {
        'Hoje': [moment().toDate(), moment().toDate()],
        'Ontem': [
          moment().subtract(1, 'days').toDate(),
          moment().subtract(1, 'days').toDate(),
        ],
        'Semana atual': [
          moment().startOf('week'),
          moment().endOf('week'),
        ],
        'Últimos 7 dias': [
          moment().subtract(6, 'days').toDate(),
          moment().toDate(),
        ],
        'Últimos 30 dias': [
          moment().subtract(29, 'days').toDate(),
          moment().toDate(),
        ],
        'Mês atual': [
          moment().startOf('month').toDate(),
          moment().endOf('month').toDate(),
        ],
        'Mês passado': [
          moment().subtract(1, 'month').startOf('month').toDate(),
          moment().subtract(1, 'month').endOf('month').toDate(),
        ],
      },
      locale: {
        applyLabel: 'Aplicar',
        cancelLabel: 'Cancelar',
        daysOfWeek: [
          'D',
          'S',
          'T',
          'Q',
          'Q',
          'S',
          'S'
        ]
      }
    }
    const { ExportCSVButton } = CSVExport;

    function priceFormatter(cell) {
      const cellValue = typeof cell === 'string' ? parseFloat(cell.replace('.', '').replace(',', '.')) : cell;

      return cellValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }

    const columns = [{
      dataField: 'produto_nome',
      text: 'Produto',
      sort: true,
      style: { fontWeight: 'bold' },
      headerStyle: (column, colIndex) => { return { width: '300px' }; },
      filter: textFilter({
        placeholder: 'Filtrar',
      }),
      footer: 'Subtotal',
      footerAttrs: {
        colSpan: 2
      }
    }, {
      dataField: 'produto_sku',
      text: 'SKU',
      sort: true,
      filter: textFilter({
        placeholder: 'Filtrar',
      })
    }, {
      dataField: 'preco_cheio',
      text: 'Preço cheio',
      sort: true,
      formatter: priceFormatter,
      csvFormatter: priceFormatter,
      filter: textFilter({
        placeholder: 'Filtrar',
      }),
      footer: columnData => priceFormatter(columnData.reduce((acc, item) => { const itemValue = typeof item === 'string' ? parseFloat(item.replace('.', '').replace(',', '.')) : item; return acc + itemValue }, 0))
    }, {
      dataField: 'reservados',
      text: 'Reservados',
      sort: true,
      filter: textFilter({
        placeholder: 'Filtrar',
      }),
      footer: columnData => columnData.reduce((acc, item) => acc + item, 0)
    }, {
      dataField: 'vendidos',
      text: 'Vendidos',
      sort: true,
      filter: textFilter({
        placeholder: 'Filtrar',
      }),
      footer: columnData => columnData.reduce((acc, item) => acc + item, 0)
    }, {
      dataField: 'desconto_medio',
      text: 'Desconto médio',
      sort: true,
      formatter: cell => cell.toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 }),
      csvFormatter: cell => cell.toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 }),
      filter: textFilter({
        placeholder: 'Filtrar',
      }),
      footer: ''
    }, {
      dataField: 'valor_estimativa',
      text: 'Valor estimativa',
      sort: true,
      formatter: priceFormatter,
      csvFormatter: priceFormatter,
      filter: textFilter({
        placeholder: 'Filtrar',
      }),
      footer: columnData => priceFormatter(columnData.reduce((acc, item) => { const itemValue = typeof item === 'string' ? parseFloat(item.replace('.', '').replace(',', '.')) : item; return acc + itemValue }, 0))
    }, {
        dataField: 'valor_vendido',
      text: 'Valor vendido',
      sort: true,
      formatter: priceFormatter,
      csvFormatter: priceFormatter,
      filter: textFilter({
        placeholder: 'Filtrar',
      }),
      footer: columnData => priceFormatter(columnData.reduce((acc, item) => { const itemValue = typeof item === 'string' ? parseFloat(item.replace('.', '').replace(',', '.')) : item; return acc + itemValue }, 0))
    }, {
      dataField: null,
      text: '',
      isDummyField: true,
      csvExport: false,
      formatter: (cell, row) => <button type="button" className="btn btn-primary w-100" onClick={() => this.setState({ currentTab: 'detailed_product', productId: row.id })}>Visualizar</button>,
      footer: ''
    }];

    const NoDataIndication = () => {
      return this.state.loading ? (<div className="py-4"><span className="spinner"></span></div>) : 'Nenhum resultado encontrado'
    }

    const productsTable = (
      <ToolkitProvider
        bootstrap4
        keyField='id'
        data={this.state.productsTable.entries}
        columns={columns}
        exportCSV={{ fileName: 'pedidos_vendidos.csv', onlyExportFiltered: true, exportAll: false, noAutoBOM: false }}
        search
      >
        {
          props => (
            <>
              <BootstrapTable
                {...props.baseProps}
                filter={filterFactory()}
                pagination={
                  paginationFactory(
                    {
                      showTotal: true,
                      sizePerPageList: [
                        { text: '10', value: 10 },
                        { text: '20', value: 20 },
                        { text: '50', value: 50 },
                        { text: '100', value: 100 },
                      ],
                      paginationTotalRenderer: (from, to, size) => (
                        <span className="ml-2 react-bootstrap-table-pagination-total">
                          Mostrando {from} para {to} de {size} resultados
                        </span>
                      )
                    }
                  )
                }
                defaultSorted={
                  [{
                    dataField: 'pedido_created',
                    order: 'desc'
                  }]
                }
                striped
                hover
                condensed
                noDataIndication={() => <NoDataIndication />}
                filterPosition="top"
                wrapperClasses="table-responsive"
              />
              <div className="row">
                <div className="col-12">
                  <ExportCSVButton className="btn btn-secondary float-right" {...props.csvProps}>Exportar CSV</ExportCSVButton>
                </div>
              </div>
            </>
          )
        }
      </ToolkitProvider>
    );

    if (this.state.currentTab === 'selled_products') {
      productsContent = (
        <React.Fragment>
          <div className="row mb-2">
            <div className="col-md-3">
              <DateRangePicker
                initialSettings={dateRangePickerSettings}
                onCallback={this.handleDateRangePickerCallback}
                onApply={this.handleApply}
              >
                <input type="text" className="form-control" value={this.state.initialValue} />
              </DateRangePicker>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 m-b-20">
              <div className="card h-100 shadow-sm">
                <div className="card-body">
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col" className="px-0"></th>
                        <th scope="col">Quantidade</th>
                        <th scope="col">Unitários</th>
                        <th scope="col">Faturamento</th>
                        <th scope="col">Desconto</th>
                        <th scope="col">Frete Pago</th>
                        <th scope="col">Frete Grátis</th>
                        <th scope="col">Cupom</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th className="px-0">Vendidos</th>
                        <th>{this.state.productsTable.summary.countSold}</th>
                        <th>{this.state.productsTable.summary.unitaryCountSold}</th>
                        <th>{this.state.productsTable.summary.billingSold.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</th>
                        <th>{this.state.productsTable.summary.avgDiscountSold.toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 })}</th>
                        <th>{this.state.productsTable.summary.shippingPaidSold.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</th>
                        <th>{this.state.productsTable.summary.countFreeShippingSold}({this.state.productsTable.summary.percentageOfTotalFreeShippingSold.toLocaleString('pt-br', { style: 'percent' })})</th>
                        <th>{this.state.productsTable.summary.countCouponSold}({this.state.productsTable.summary.percentageOfCouponSold.toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 })})</th>
                      </tr>
                      <tr>
                        <th className="px-0">Reservados</th>
                        <th>{this.state.productsTable.summary.countReserved}</th>
                        <th>{this.state.productsTable.summary.unitaryCountReserved}</th>
                        <th>{this.state.productsTable.summary.billingReserved.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</th>
                        <th>{this.state.productsTable.summary.avgDiscountReserved.toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 })}</th>
                        <th>{this.state.productsTable.summary.shippingPaidReserved.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</th>
                        <th>{this.state.productsTable.summary.countFreeShippingReserved}({this.state.productsTable.summary.percentageOfTotalFreeShippingReserved.toLocaleString('pt-br', { style: 'percent' })})</th>
                        <th>{this.state.productsTable.summary.countCouponReserved}({this.state.productsTable.summary.percentageOfCouponReserved.toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 })})</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <div className="row mt-0 m-b-30" >
                    <div className="col-md-6">
                      <h4 className="header-title">Produtos</h4>
                      <select className="form-control form-control-sm" value={this.state.filterProducts} onChange={this.handleChangeFilterProducts}>
                        <option value="all_products">Mostrar todos os produtos</option>
                        <option value="only_unitary">Mostrar somente produtos unitários</option>
                        <option value="only_kit">Mostrar somente kits</option>
                      </select>
                    </div>
                  </div>
                  {productsTable}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else if (this.state.currentTab === 'detailed_product') {
      productsContent = (
        <DetailedProduct
          changeCurrentTab={this.changeCurrentTab.bind(this)}
          previousTab={'selled_products'}
          nameTab={'produtos vendidos'}
          productId={this.state.productId}
        />
      );
    }

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            {productsContent}
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = store => {
  return {
    order_updated: store.ui_red.order_updated
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateOrders: (order_updated) => dispatch({ type: actionTypes.ORDERUPDATED, value: order_updated }),
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(SelledProducts);