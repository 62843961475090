import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import AUX from '../../hoc/Aux_';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';

class layout extends Component {

  render() {
    return (
      <AUX>
        {this.props.is_authenticated ?
          <main>
            <div id="wrapper">
              {this.props.sidebar ? <Sidebar/> : null}
              <div className="content-page">
                <div className="content">
                  {this.props.header ? <Header/> : null}
                  {this.props.children}
                </div>
                {this.props.footer ? <Footer/> : null}
              </div>
            </div>
          </main>
        : 
          this.props.children
        }
      </AUX> 
    );
  }
}

export default withRouter(layout);
