import React , { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Panel from '../MainContent/Dashboard/Panel';
import Dashboard from '../MainContent/Dashboard/Dashboard';
import Forecast from '../MainContent/Dashboard/Forecast';
import Orders from '../MainContent/Dashboard/Orders/Orders';
import OrdersCanceled from '../MainContent/Dashboard/Orders/OrdersCanceled';
import CampaignsOrders from '../MainContent/Dashboard/Orders/CampaignsOrders';
import OrdersPerState from '../MainContent/Dashboard/Orders/OrdersPerState';
import Products from '../MainContent/Dashboard/Products/Products';
import SelledProducts from '../MainContent/Dashboard/Products/SelledProducts';
import TopSellingProducts from '../MainContent/Dashboard/Products/TopSellingProducts';
import Clients from '../MainContent/Dashboard/Clients/Clients';
import Users from '../MainContent/Settings/Users/Users';

class mainbuilder extends Component {
  render(){
    const currentUser = JSON.parse(localStorage.getItem('user'));

    return(
      <Switch> 
        <Route path="/dashboard" component={(currentUser.owner_role || currentUser.superadmin_role) ? Dashboard : Orders} />
        <Route path="/forecast" component={(currentUser.owner_role || currentUser.superadmin_role) ? Forecast : Orders} />
        <Route path="/orders" component={Orders} />
        <Route path="/orders_canceled" component={OrdersCanceled} />
        <Route path="/campaigns_orders" component={(currentUser.owner_role || currentUser.superadmin_role) ? CampaignsOrders : Orders} />
        <Route path="/orders_per_state" component={(currentUser.owner_role || currentUser.superadmin_role) ? OrdersPerState : Orders} />
        <Route path="/products" component={Products} />
        <Route path="/selled_products" component={SelledProducts} />
        <Route path="/top_selling_products" component={(currentUser.owner_role || currentUser.superadmin_role) ? TopSellingProducts : Orders} />
        <Route path="/clients" component={Clients} />
        <Route path="/users" component={(currentUser.owner_role || currentUser.superadmin_role) ? Users : Orders} />

        <Route path="/" component={Panel} />
      </Switch>
    );
  }
}

export default mainbuilder;