import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import pt from 'date-fns/locale/pt';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { InsertChart } from '@material-ui/icons';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { format as formatPhone } from 'telefone';

import { doGet } from '../../../helpers/ApiHelper';
import AUX from '../../../hoc/Aux_';
import * as actionTypes from '../../../store/action';
import OrderChart from './Charts/Order';
import BillingChart from './Charts/Billing';

class Panel extends Component {
  constructor(props) {
    registerLocale('pt', pt);
    setDefaultLocale('pt');

    super(props);

    this.state = {
      resumeTitle: 'Resumo do dia',
      resumeOrders: { total: 0, count: 0 },
      averageTicket: 0,
      newClients: 0,
      recurrentClients: 0,
      billingPerHourChart: {},
      billingAccumulated: {},
      billingForecast: {
        label: 0,
        forecast: { data: 0 },
        realized: { data: 0 },
        realizedApproveds: { data: 0 },
        realizedWaitingPayments: { data: 0 },
        beforeRealized: { data: 0 }
      },
      ordersPerHourChart: {},
      ordersAccumulated: {},
      ordersForecast: {
        label: 0,
        forecast: { data: 0 },
        realized: { data: 0 },
        realizedApproveds: { data: 0 },
        realizedWaitingPayments: { data: 0 },
        beforeRealized: { data: 0 }
      },
      allOrders: { total: 0, count: 0 },
      approvedOrders: { total: 0, count: 0 },
      awaitingPayments: { total: 0, count: 0 },
      canceledOrders: { total: 0, count: 0 },
      currentTab: 'panel',
      orderId: null,
      ordersInfoLoaded: false,
      chartSelected: 'chart_billing',
      chartTypeSelected: 'chart_forecast',
      periodSelected: 'today',
      startDate: new Date(),
      endDate: new Date(),
      topSellingProductsLoaded: false,
      topSellingProductsData: null,
      canceledOrdersLoaded: false,
      canceledOrdersData: null,
      campaignsOrdersLoaded: false,
      campaignsOrdersData: null,
      ordersPerStateOrdersLoaded: false,
      ordersPerStateOrdersData: null
    };

    this.currentUser = JSON.parse(localStorage.getItem('user'));
  }

  componentDidMount() {
    this.handleSearch(false);
  }

  onChartChange(value) {
    this.setState({ chartSelected: value });
  }

  onTypeChartChange(value) {
    this.setState({ chartTypeSelected: value });
  }

  changeCurrentTab(tab) {
    window.scrollTo(0, 0);
    this.setState({ currentTab: tab });
  }

  handleSearch(filters = true) {
    const today = moment(new Date()).format('YYYY-MM-DD');
    const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
    const start_date = moment(this.state.startDate).format('YYYY-MM-DD');
    const end_date = moment(this.state.endDate).format('YYYY-MM-DD');
    let resume_title = 'Resumo do período';
    let period_selected = 'period';

    if (start_date === today && end_date === today) {
      period_selected = 'today';
      resume_title = 'Resumo do dia';
    } else if (start_date === yesterday && end_date === yesterday) {
      period_selected = 'yesterday';
      resume_title = 'Resumo de ontem';
    }

    this.setState({
      ordersInfoLoaded: false,
      chartSelected: this.currentUser.owner_role ? 'chart_billing' : 'chart_order',
      chartTypeSelected: 'chart_forecast',
      resumeTitle: resume_title,
      periodSelected: period_selected,
      topSellingProductsLoaded: false,
      topSellingProductsData: null,
      canceledOrdersLoaded: false,
      canceledOrdersData: null,
      campaignsOrdersLoaded: false,
      campaignsOrdersData: null,
      ordersPerStateOrdersLoaded: false,
      ordersPerStateOrdersData: null
    });
    let params = '';

    if (filters === true) params = `?start_date=${start_date}&end_date=${end_date}`;

    doGet(`/orders/panel${params}`).then(response =>
      this.setState({
        ordersInfoLoaded: true,
        resumeOrders: { total: response['resume_orders']['total'], count: response['resume_orders']['count'] },
        averageTicket: response['average_ticket'],
        newClients: response['new_clients'],
        recurrentClients: response['recurrent_clients'],
        billingPerHourChart: response['billing_per_hour'] === false ? false : { labels: Object.keys(response['billing_per_hour']), datasets: Object.values(response['billing_per_hour']) },
        billingAccumulated: response['billing_accumulated'] === false ? false : { labels: Object.keys(response['billing_accumulated']), datasets: Object.values(response['billing_accumulated']) },
        billingForecast: {
          label: response['billing_forecast']['label'],
          forecast: {
            data: response['billing_forecast']['forecast']['data']
          },
          realized: {
            data: response['billing_forecast']['realized']['data']
          },
          realizedApproveds: {
            data: response['billing_forecast']['realized_approveds']['data']
          },
          realizedWaitingPayments: {
            data: response['billing_forecast']['realized_waiting_payments']['data']
          },
          beforeRealized: {
            data: response['billing_forecast']['before_realized']['data']
          },
        },
        ordersPerHourChart: response['orders_per_hour'] === false ? false : { labels: Object.keys(response['orders_per_hour']), datasets: Object.values(response['orders_per_hour']) },
        ordersAccumulated: response['orders_accumulated'] === false ? false : { labels: Object.keys(response['orders_accumulated']), datasets: Object.values(response['orders_accumulated']) },
        ordersForecast: {
          label: response['orders_forecast']['label'],
          forecast: {
            data: response['orders_forecast']['forecast']['data']
          },
          realized: {
            data: response['orders_forecast']['realized']['data']
          },
          realizedApproveds: {
            data: response['orders_forecast']['realized_approveds']['data']
          },
          realizedWaitingPayments: {
            data: response['orders_forecast']['realized_waiting_payments']['data']
          },
          beforeRealized: {
            data: response['orders_forecast']['before_realized']['data']
          },
        },
        allOrders: { total: response['all_orders']['total'], count: response['all_orders']['count'] },
        approvedOrders: { total: response['approved_orders']['total'], count: response['approved_orders']['count'] },
        awaitingPayments: { total: response['awaiting_payments']['total'], count: response['awaiting_payments']['count'] },
        canceledOrders: { total: response['canceled_orders']['total'], count: response['canceled_orders']['count'] }
      }, () => {
          if (this.currentUser.superadmin_role || this.currentUser.owner_role) {
            params = params !== '' ? params + '&limit=true' : '?limit=true';
            doGet(`/orders/top_selling_products${params}`).then(response => {
              this.setState({
                topSellingProductsLoaded: true,
                topSellingProductsData: response
              })
            }).catch(() => this.setState({ topSellingProductsLoaded: true, topSellingProductsData: null }));

            doGet(`/orders/orders_canceled${params}`).then(response => {
              this.setState({
                canceledOrdersLoaded: true,
                canceledOrdersData: response
              })
            }).catch(() => this.setState({ canceledOrdersLoaded: true, canceledOrdersData: null }));

            doGet(`/orders/campaigns${params}`).then(response => {
              this.setState({
                campaignsOrdersLoaded: true,
                campaignsOrdersData: response
              })
            }).catch(() => this.setState({ campaignsOrdersLoaded: true, campaignsOrdersData: null }));

            doGet(`/orders/per_state${params}`).then(response => {
              this.setState({
                ordersPerStateOrdersLoaded: true,
                ordersPerStateOrdersData: response
              })
            }).catch(() => this.setState({ ordersPerStateOrdersLoaded: true, ordersPerStateOrdersData: null }));
          }
      })
    )
    .catch(() => {
      this.setState({ ordersInfoLoaded: true });
    });
  }

  handleStart = date => {
    this.setState({ startDate: date });
  }
  handleEnd = date => {
    this.setState({ endDate: date });
  }

  periodChanged = period => {
    this.setState({ periodSelected: period }, () => {
      switch (period) {
        case 'today':
          this.setState({ resumeTitle: 'Resumo do dia', periodSelected: 'today', startDate: new Date(), endDate: new Date() }, () => this.handleSearch());
          break;
        case 'yesterday':
          this.setState({ resumeTitle: 'Resumo de ontem', periodSelected: 'yesterday', startDate: moment().subtract(1, 'days').toDate(), endDate: moment().subtract(1, 'days').toDate() }, () => this.handleSearch());
          break;
        default:
          break;
      }
    });
  }

  componentDidUpdate() {
    if (this.props.order_updated === true) {
      this.setState({
        resumeTitle: 'Resumo do dia',
        periodSelected: 'today',
        startDate: new Date(),
        endDate: new Date()
      }, () => { this.handleSearch(); this.props.UpdateOrders(false); });
    }
  }

  hideElement = () => {
    if (!this.currentUser.owner_role) return { display: 'none' }
  }

  render() {
    const loader = (<span className="m-auto spinner" />);
    const loaderDiv = (<div className="py-4"><span className="spinner" /></div>);
    let panelContent = (<span className="m-auto spinner" />);
    let panelFilterOptions = (
      <div className="row">
        <div className="col-md-12 m-b-20">
          <div className="d-flex justify-content-between">
            <div style={{ verticalAlign: 'middle', fontSize: '24px', color: '#6c757d', display: 'inline-block' }}>
              <InsertChart style={{ color: '#000', fontSize: '35px' }} /> <button type="button" className={`btn btn-link btn-panel p-0 ${this.state.periodSelected === 'today' ? 'active' : ''}`} style={{ fontSize: '24px' }} onClick={() => this.periodChanged('today')}>Hoje</button> | <button type="button" className={`btn btn-link btn-panel p-0 ${this.state.periodSelected === 'yesterday' ? 'active' : ''}`} style={{ fontSize: '24px' }} onClick={() => this.periodChanged('yesterday')}>Ontem</button>
            </div>
            <div className="form-row" style={this.hideElement()}>
              <div className="col">
                <DatePicker
                  selectsStart
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  selected={this.state.startDate}
                  onChange={this.handleStart}
                  placeholderText="Início"
                  minDate={new Date(2014, 0, 1)}
                  maxDate={new Date()}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  showMonthDropdown
                  showYearDropdown
                />
              </div>
              <div className="col">
                <DatePicker
                  selectsEnd
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  selected={this.state.endDate}
                  onChange={this.handleEnd}
                  placeholderText="Fim"
                  minDate={this.state.startDate}
                  maxDate={new Date()}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  showMonthDropdown
                  showYearDropdown
                />
              </div>
              <button type="button" className="btn btn-primary" onClick={() => this.handleSearch()}>Filtrar</button>
            </div>
          </div>
        </div>
      </div>
    );
    let chartSelected = null;

    if (this.state.currentTab === 'panel') {
      let cardOrdersContent = null;
      let topSellingProductsTable = loaderDiv;
      let canceledOrdersTable = loaderDiv;
      let campaignsOrdersTable = loaderDiv;
      let ordersPerStateOrdersTable = loaderDiv;

      if (this.state.ordersInfoLoaded) {
        if (this.state.chartSelected === 'chart_order') {
          chartSelected = (
            <OrderChart
              chartTypeSelected={this.state.chartTypeSelected}
              ordersPerHourChart={this.state.ordersPerHourChart}
              ordersAccumulated={this.state.ordersAccumulated}
              ordersForecast={this.state.ordersForecast}
            />
          );
        } else if (this.state.chartSelected === 'chart_billing') {
          chartSelected = (
            <BillingChart
              chartTypeSelected={this.state.chartTypeSelected}
              billingPerHourChart={this.state.billingPerHourChart}
              billingAccumulated={this.state.billingAccumulated}
              billingForecast={this.state.billingForecast}
            />
          );
        }

        const realizedOrdersCard = (
          <div className="col-md-6 col-xl-3">
            <div className="card m-b-20 shadow-sm">
              <div className="card-body" style={{ borderRadius: '0.15rem', borderLeft: '4px solid #F9CB79' }}>
                <div className="row">
                  <div className="col-md-12">
                    <h6 className="header-title text-secondary font-weight-light">Pedidos efetuados</h6>
                  </div>
                  <div className="col-md-12" style={{ fontSize: '22px' }}>
                    <strong>{this.state.allOrders.count.toLocaleString('pt-br', { style: 'decimal', currency: 'BRL' })}</strong> <small className="text-muted" style={this.hideElement()}>/ {this.state.allOrders.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

        const approvedOrdersCard = (
          <div className="col-md-6 col-xl-3">
            <div className="card m-b-20 shadow-sm">
              <div className="card-body" style={{ borderRadius: '0.15rem', borderLeft: '4px solid #61B793' }}>
                <div className="row">
                  <div className="col-md-12">
                    <h6 className="header-title text-secondary font-weight-light">Pedidos aprovados</h6>
                  </div>
                  <div className="col-md-12" style={{ fontSize: '22px' }}>
                    <strong>{this.state.approvedOrders.count.toLocaleString('pt-br', { style: 'decimal', currency: 'BRL' })}</strong> <small className="text-muted" style={this.hideElement()}>/ {this.state.approvedOrders.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

        const awaitingPaymentCard = (
          <div className="col-md-6 col-xl-3">
            <div className="card m-b-20 shadow-sm">
              <div className="card-body" style={{ borderRadius: '0.15rem', borderLeft: '4px solid #E0A458' }}>
                <div className="row">
                  <div className="col-md-12">
                    <h6 className="header-title text-secondary font-weight-light">Aguardando pagamento</h6>
                  </div>
                  <div className="col-md-12" style={{ fontSize: '22px' }}>
                    <strong>{this.state.awaitingPayments.count.toLocaleString('pt-br', { style: 'decimal', currency: 'BRL' })}</strong> <small className="text-muted" style={this.hideElement()}>/ {this.state.awaitingPayments.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

        const canceledOrdersCard = (
          <div className="col-md-6 col-xl-3">
            <div className="card m-b-20 shadow-sm">
              <div className="card-body" style={{ borderRadius: '0.15rem', borderLeft: '4px solid #D16050' }}>
                <div className="row">
                  <div className="col-md-12">
                    <h6 className="header-title text-secondary font-weight-light">Pedidos cancelados</h6>
                  </div>
                  <div className="col-md-12" style={{ fontSize: '22px' }}>
                    <strong>{this.state.canceledOrders.count.toLocaleString('pt-br', { style: 'decimal', currency: 'BRL' })}</strong> <small className="text-muted" style={this.hideElement()}>/ {this.state.canceledOrders.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

        const resumeOrdersInfo = (
          <div className="row">
            <div className="col-md-12">
              <h6 className="header-title text-secondary font-weight-light mb-0">Resultado</h6>
            </div>
            <div className="col-md-12" style={{ fontSize: '22px' }}>
              <strong>{this.state.resumeOrders.count.toLocaleString('pt-br', { style: 'decimal', currency: 'BRL' })}</strong> <small className="text-muted" style={this.hideElement()}>/ {this.state.resumeOrders.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</small>
            </div>
          </div>
        );

        const averageTicketInfo = (
          <div className="row" style={this.hideElement()}>
            <div className="col-md-12">
              <h6 className="header-title text-secondary font-weight-light mb-0">Ticket médio</h6>
            </div>
            <div className="col-md-12" style={{ fontSize: '22px' }}>
              <strong>{this.state.averageTicket.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
            </div>
          </div>
        );

        const newClientsInfo = (
          <div className="row">
            <div className="col-md-12">
              <h6 className="header-title text-secondary font-weight-light mb-0">Novos clientes</h6>
            </div>
            <div className="col-md-12" style={{ fontSize: '22px' }}>
              <strong>{this.state.newClients.toLocaleString('pt-br', { style: 'decimal', currency: 'BRL' })}</strong>
            </div>
          </div>
        );

        const recurrentClientsInfo = (
          <div className="row">
            <div className="col-md-12">
              <h6 className="header-title text-secondary font-weight-light mb-0">Clientes recorrentes</h6>
            </div>
            <div className="col-md-12" style={{ fontSize: '22px' }}>
              <strong>{this.state.recurrentClients.toLocaleString('pt-br', { style: 'decimal', currency: 'BRL' })}</strong>
            </div>
          </div>
        );

        if (this.currentUser.superadmin_role || this.currentUser.owner_role) {
          if (this.state.topSellingProductsLoaded) {
            let tbodyContent = null;

            if (this.state.topSellingProductsData != null) {
              if (this.state.topSellingProductsData.entries.length > 0) {
                tbodyContent = (
                  <>
                    <tbody>
                      {this.state.topSellingProductsData.entries.map((row) => {
                        const valor_total = typeof row.valor_total === 'string' ? parseFloat(row.valor_total.replace('.', '').replace(',', '.')) : row.valor_total;
                        return (
                          <tr key={row.id}>
                            <th className="align-middle" scope="row">{row.nome}</th>
                            <td className="align-middle">{row.qtd_vendido}</td>
                            <td className="align-middle">{valor_total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Subtotal</th>
                        <td>{this.state.topSellingProductsData.qtd_produtos_vendidos}</td>
                        <td>{this.state.topSellingProductsData.valor_total_vendido.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                      </tr>
                    </tfoot>
                  </>
                );
              } else {
                tbodyContent = (
                  <tbody>
                    <tr>
                      <td colSpan="3" className="text-center font-weight-bold">Nenhum resultado encontrado</td>
                    </tr>
                  </tbody>
                );
              }
            }

            topSellingProductsTable = (
              <>
                <div style={{ height: '350px', overflowY: 'auto' }}>
                  <table className="table table-hover table-sm">
                    <thead>
                      <tr>
                        <th scope="col">Nome</th>
                        <th scope="col">Vendidos</th>
                        <th scope="col">Valor total</th>
                      </tr>
                    </thead>
                    {tbodyContent}
                  </table>
                </div>
                <Link to={{ pathname: 'top_selling_products', startDate: this.state.startDate, endDate: this.state.endDate }} className="mt-auto text-center" >Ver mais</Link>
              </>
            );
          }

          if (this.state.canceledOrdersLoaded) {
            let tbodyContent = null;

            if (this.state.canceledOrdersData != null) {
              if (this.state.canceledOrdersData.entries.length > 0) {
                tbodyContent = (
                  <>
                    <tbody>
                      {this.state.canceledOrdersData.entries.map((row) => {
                        const pedido_valor = typeof row.pedido_valor === 'string' ? parseFloat(row.pedido_valor.replace('.', '').replace(',', '.')) : row.pedido_valor;
                        return (
                          <tr key={row.pedido_id}>
                            <th className="align-middle" scope="row">{row.cliente_nome}</th>
                            <td className="align-middle">{row.cliente_email}</td>
                            <td className="align-middle">{formatPhone(row.cliente_telefone_principal === '' ? row.cliente_telefone_celular : row.cliente_telefone_principal)}</td>
                            <td className="align-middle">{pedido_valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            <td className="align-middle">{row.pedido_created}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colSpan="3">Subtotal</th>
                        <td>{this.state.canceledOrdersData.valor_total_pedidos_cancelados.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </>
                );
              } else {
                tbodyContent = (
                  <tbody>
                    <tr>
                      <td colSpan="5" className="text-center font-weight-bold">Nenhum resultado encontrado</td>
                    </tr>
                  </tbody>
                );
              }
            }

            canceledOrdersTable = (
              <>
                <div style={{ height: '350px', overflowY: 'auto' }}>
                  <table className="table table-hover table-sm">
                    <thead>
                      <tr>
                        <th scope="col">Cliente</th>
                        <th scope="col">E-mail</th>
                        <th scope="col">Telefone</th>
                        <th scope="col">Valor</th>
                        <th scope="col">Pedido criado em</th>
                      </tr>
                    </thead>
                    {tbodyContent}
                  </table>
                </div>
                <Link to={{ pathname: 'orders_canceled', startDate: this.state.startDate, endDate: this.state.endDate }} className="mt-auto text-center" >Ver mais</Link>
              </>
            );
          }

          if (this.state.campaignsOrdersLoaded) {
            let tbodyContent = null;

            if (this.state.campaignsOrdersData != null) {
              if (this.state.campaignsOrdersData.entries.length > 0) {
                tbodyContent = (
                  <>
                    <tbody>
                      {this.state.campaignsOrdersData.entries.map((row) => {
                        const faturamento = typeof row.faturamento === 'string' ? parseFloat(row.faturamento.replace('.', '').replace(',', '.')) : row.faturamento;
                        return (
                          <tr key={row.utm}>
                            <th className="align-middle" scope="row">{row.utm}</th>
                            <td className="align-middle">{faturamento.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            <td className="align-middle">{row.qtd_pedidos}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Subtotal</th>
                        <td>{this.state.campaignsOrdersData.valor_total_faturamento.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                        <td>{this.state.campaignsOrdersData.qtd_pedidos}</td>
                      </tr>
                    </tfoot>
                  </>
                );
              } else {
                tbodyContent = (
                  <tbody>
                    <tr>
                      <td colSpan="3" className="text-center font-weight-bold">Nenhum resultado encontrado</td>
                    </tr>
                  </tbody>
                );
              }
            }

            campaignsOrdersTable = (
              <>
                <div style={{ height: '350px', overflowY: 'auto' }}>
                  <table className="table table-hover table-sm">
                    <thead>
                      <tr>
                        <th scope="col">UTM</th>
                        <th scope="col">Faturamento</th>
                        <th scope="col">Qtd Pedidos</th>
                      </tr>
                    </thead>
                    {tbodyContent}
                  </table>
                </div>
                <Link to={{ pathname: 'campaigns_orders', startDate: this.state.startDate, endDate: this.state.endDate }} className="mt-auto text-center" >Ver mais</Link>
              </>
            );
          }

          if (this.state.ordersPerStateOrdersLoaded) {
            let tbodyContent = null;

            if (this.state.ordersPerStateOrdersData != null) {
              if (this.state.ordersPerStateOrdersData.entries.length > 0) {
                tbodyContent = (
                  <>
                    <tbody>
                      {this.state.ordersPerStateOrdersData.entries.map((row) => (
                        <tr key={row.uf}>
                          <th className="align-middle" scope="row">{row.uf}</th>
                          <td className="align-middle">{row.qtd_pedidos}</td>
                          <td className="align-middle">{row.valor_total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>Subtotal</th>
                        <td>{this.state.ordersPerStateOrdersData.qtd_pedidos}</td>
                        <td>{this.state.ordersPerStateOrdersData.valor_total_pedidos.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                      </tr>
                    </tfoot>
                  </>
                );
              } else {
                tbodyContent = (
                  <tbody>
                    <tr>
                      <td colSpan="3" className="text-center font-weight-bold">Nenhum resultado encontrado</td>
                    </tr>
                  </tbody>
                );
              }
            }

            ordersPerStateOrdersTable = (
              <>
                <div style={{ height: '350px', overflowY: 'auto' }}>
                  <table className="table table-hover table-sm">
                    <thead>
                      <tr>
                        <th scope="col">UF</th>
                        <th scope="col">Qtd Pedidos</th>
                        <th scope="col">Valor total</th>
                      </tr>
                    </thead>
                    {tbodyContent}
                  </table>
                </div>
                <Link to={{ pathname: 'orders_per_state', startDate: this.state.startDate, endDate: this.state.endDate }} className="mt-auto text-center" >Ver mais</Link>
              </>
            );
          }

          cardOrdersContent = (
            <>
              <div className="row">
                <div className="col-md-6">
                  <div className="card m-b-20">
                    <div className="card-body d-flex flex-column">
                      <h6 className="text-center">Produtos mais vendidos</h6>
                      {topSellingProductsTable}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="card m-b-20">
                    <div className="card-body d-flex flex-column">
                      <h6 className="text-center">Pedidos cancelados</h6>
                      {canceledOrdersTable}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="card m-b-20">
                    <div className="card-body d-flex flex-column">
                      <h6 className="text-center">Pedidos por campanhas</h6>
                      {campaignsOrdersTable}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="card m-b-20">
                    <div className="card-body d-flex flex-column">
                      <h6 className="text-center">Pedidos por estados</h6>
                      {ordersPerStateOrdersTable}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }

        panelContent = (
          <React.Fragment>
            <div className="row">
              <div className="col-md-3 m-b-30">
                <div className="card shadow-sm h-100">
                  <div className="card-body">
                    <div className="row mt-0 m-b-30">
                      <div className="col-md-12 mb-3">
                        <h4 className="header-title" style={{ fontSize: '22px' }}>{this.state.resumeTitle}</h4>
                      </div>
                      <div className="col-md-12">
                        {resumeOrdersInfo}
                        {averageTicketInfo}
                        {newClientsInfo}
                        {recurrentClientsInfo}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-9 m-b-30">
                <div className="card shadow-sm h-100">
                  <div className="card-body">
                    <div className="row mt-0 m-b-30">
                      <div className="col-md-12">
                        <button type="button" className={`btn btn-link btn-panel p-0 ${this.state.chartSelected === 'chart_order' ? 'active' : ''}`} onClick={() => this.onChartChange('chart_order')}>Pedidos</button> <span style={this.hideElement()}>| <button type="button" className={`btn btn-link btn-panel p-0 ${this.state.chartSelected === 'chart_billing' ? 'active' : ''}`} onClick={() => this.onChartChange('chart_billing')}>Faturamento</button></span>
                        <div className="float-right">
                          <button type="button" className={`btn btn-link btn-panel p-0 ${this.state.chartTypeSelected === 'chart_per_hour' ? 'active' : ''}`} disabled={(this.state.ordersPerHourChart === false && this.state.billingPerHourChart === false) ? true : false} onClick={() => this.onTypeChartChange('chart_per_hour')}>Hora</button>{' | '}
                          <button type="button" className={`btn btn-link btn-panel p-0 ${this.state.chartTypeSelected === 'chart_accumulated' ? 'active' : ''}`} disabled={(this.state.ordersAccumulated === false || this.state.billingAccumulated === false) ? true : false} onClick={() => this.onTypeChartChange('chart_accumulated')}>Acumulado</button>{' | '}
                          <button type="button" className={`btn btn-link btn-panel p-0 ${this.state.chartTypeSelected === 'chart_forecast' ? 'active' : ''}`} disabled={(this.state.ordersForecast === false || this.state.billingForecast === false) ? true : false} onClick={() => this.onTypeChartChange('chart_forecast')}>Projeção</button>
                        </div>
                      </div>
                    </div>
                    <div style={{ maxHeight: '275px' }}>
                      {chartSelected}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {realizedOrdersCard}
              {approvedOrdersCard}
              {awaitingPaymentCard}
              {canceledOrdersCard}
            </div>
            {cardOrdersContent}
          </React.Fragment>
        );
      } else {
        panelContent = loader;
      }
    }

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            {panelFilterOptions}
            {panelContent}
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = store => {
  return {
    order_updated: store.ui_red.order_updated
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateOrders: (order_updated) => dispatch({ type: actionTypes.ORDERUPDATED, value: order_updated }),
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Panel);