import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap';
import { connect } from 'react-redux';

import * as actionTypes from '../../store/action';
import { doGet } from '../../helpers/ApiHelper';
import Auth from '../../Auth';

class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpenProfile: false,
      now_route : "",
      order_updated: false,
      last_updated_modified: ''
    };

    this.logout = this.logout.bind(this);
    this.toggleProfile = this.toggleProfile.bind(this);
    this.currentUser = JSON.parse(localStorage.getItem('user'));
  }

  logout() {
    Auth.signout(this.currentUser.id)
      .then(() => {
        this.props.Logout();
      })
      .catch(e => {
        console.log("error::", e);
      });
  }

  toggleProfile() {
    this.setState(prevState => ({
      dropdownOpenProfile: !prevState.dropdownOpenProfile
    }));
  }

  componentDidMount(){
    if (this.props.order_updated === false) this.getStatusOrders();

    this.setState({
      now_route: this.props.location.pathname
    });
  }

  getStatusOrders() {
    if (this.currentUser.superadmin_role || this.currentUser.owner_role) {
      doGet('/api_update/status?table=pedidos').then(response => this.setState({
        order_updated: false,
        last_updated_modified: `Atualizado em: ${response['modified']}`
      }), this.props.UpdateOrders(this.state.order_updated));
    }
  }

  updateOrders() {
    doGet('/orders/update_api')
      .then(response => 
        this.setState({
          order_updated: true,
          last_updated_modified: `Atualizado em: ${response['api_update']['modified']}`
        }, () => this.props.UpdateOrders(true))
      )
      .catch(error => {
        this.setState({
          order_updated: false,
          last_updated_modified: `Ocorreu um erro na atualização, tente novamente.`
        }, () => this.props.UpdateOrders(this.state.order_updated))
      });
  }

  render() {
    let username = '';
    let updateInfo = null;

    if (this.currentUser.name) {
      if (this.currentUser.name.split(' ').length > 1)
        username = `${this.currentUser.name.split(' ')[0].substr(0, 1).toUpperCase()}${this.currentUser.name.split(' ')[1].substr(0, 1).toUpperCase()}`;
      else
        username = this.currentUser.name.substr(0, 2).toUpperCase();
    }

    if (this.currentUser.superadmin_role || this.currentUser.owner_role) {
      updateInfo = (
        <li className="list-inline-item dropdown notification-list">
          <div className="nav-link px-0">
            <Link onClick={() => this.setState({ last_updated_modified: 'Atualizando...' }, this.updateOrders())} to="#" id="btn-refresh">
              <i className="mdi mdi-refresh noti-icon mr-2"></i>
            </Link>
            <small><i>{this.state.last_updated_modified}</i></small>
          </div>
        </li>
      );
    }

    return (
      <div className="topbar">
        <nav className="navbar-custom">
          <ul className="list-inline float-right mb-0">
            {updateInfo}
            <li className="list-inline-item dropdown notification-list">
              <Dropdown isOpen={this.state.dropdownOpenProfile}  toggle={this.toggleProfile}>
                <DropdownToggle className="nav-link dropdown-toggle droptest arrow-none waves-effect nav-user" tag="a">
                  <span style={{ display: 'inline-block', lineHeight: '0px', borderRadius: '50%', border: '2px solid #6c757d', backgroundColor: '#6c757d' }}>
                    <span className="text-white" style={{ display: 'inline-block', paddingTop: '50%', paddingBottom: '50%', marginLeft: '8px', marginRight: '8px' }}>{username}</span>
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem><i className="mdi mdi-account-circle m-r-5"></i> Profile</DropdownItem>
                  <DropdownItem><i className="mdi mdi-settings m-r-5"></i> Settings</DropdownItem>
                  <DropdownItem onClick={this.logout}><i className="mdi mdi-power text-danger"></i> Logout</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </li>
          </ul>

          <ul className="list-inline menu-left mb-0">
            <li className="list-inline-item">
              <button type="button" className="button-menu-mobile open-left waves-effect">
                <i className="mdi mdi-menu"></i>
              </button>
            </li>
            <li className="hide-phone list-inline-item">
              <h3 className="page-title" id="now_routing">Home</h3>
            </li>
          </ul>
          <div className="clearfix"></div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    order_updated: store.ui_red.order_updated
  };
}

const mapDispatchToProps = dispatch => {
  return {
    Logout: () => dispatch({ type: actionTypes.isAuthenticated, value: false }),
    UpdateOrders: (order_updated) => dispatch({ type: actionTypes.ORDERUPDATED, value: order_updated }),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(header));
