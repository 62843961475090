import React, { Component } from 'react';
import { connect } from 'react-redux';

import AUX from '../../../../hoc/Aux_';
import { doGet, doDelete } from '../../../../helpers/ApiHelper';
import * as actionTypes from '../../../../store/action';
import EditUser from './EditUser';
import AddUser from './AddUser';

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 'users',
      userId: null,
      usersTable: [],
      usersLoaded: false
    };
  }

  componentDidMount() {
    this.loadUsers();
  }

  loadUsers = () => {
    doGet(`/users`).then(response =>
      this.setState({ usersTable: response }, () => this.setState({ usersLoaded: true }))
    );
  }

  changeCurrentTab = tab => {
    window.scrollTo(0, 0);
    this.setState({ currentTab: tab }, () => {
      if (this.state.currentTab === 'users') this.loadUsers();
    });
  }

  _removeUser = userId => {
    doDelete(`/users/${userId}`).then(response => {
      if (response.status === 204) this.setState({ usersLoaded: false }, () => this.loadUsers());
      else alert(`Erro ao deletar o usuário! userId: ${userId}`);
    });
  }

  render() {
    let usersContent = (<div className="mt-5 spinner"></div>);

    if (this.state.usersLoaded) {
      if (this.state.usersTable.length > 0) {
        const usersTable = (
          <table id="datatable" className="table table-striped table-vertical table-hover table-sm">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Superadmin</th>
                <th>Owner</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.usersTable.map((user) => {
                  return (
                    <tr key={user.id}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.superadmin_role ? 'Sim' : 'Não'}</td>
                      <td>{user.owner_role ? 'Sim' : 'Não'}</td>
                      <td>{user.active ? 'Ativo' : 'Inativo'}</td>
                      <td>
                        <button type="button" className="btn btn-link text-primary" onClick={() => this.setState({ currentTab: 'edit_user', userId: user.id })}><i className="mdi mdi-pencil font-18"></i></button>
                        <button type="button" className="btn btn-link text-danger" onClick={() => { if (window.confirm(`Você deseja excluir o usuário ${user.name} - ${user.email}?`)) this._removeUser(user.id) }}><i className="mdi mdi-close font-18"></i></button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        );

        if (this.state.currentTab === 'users') {
          usersContent = (
            <React.Fragment>
              <div className="row">
                <div className="col-md-12">
                  <div className="card m-b-20">
                    <div className="card-body shadow-sm">
                      <div className="col-md-8 offset-md-2">
                        <div className="row">
                          <div className="w-100">
                            <button type="button" className="btn btn-primary float-right" onClick={() => this.setState({ currentTab: 'add_user' })}><i className="mdi mdi-account-plus font-18"></i> Criar usuário</button>
                          </div>
                        </div>
                        <div className="row">
                          {usersTable}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        } else if (this.state.currentTab === 'edit_user') {
          usersContent = (
            <EditUser
              changeCurrentTab={this.changeCurrentTab.bind(this)}
              userId={this.state.userId}
            />
          );
        } else if (this.state.currentTab === 'add_user') {
          usersContent = (<AddUser changeCurrentTab={this.changeCurrentTab.bind(this)} />);
        }
      } else {
        usersContent = (
          <h6>Não foi encontrado nenhum resultado.</h6>
        );
      }
    }
    

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            {usersContent}
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = store => {
  return {
    order_updated: store.ui_red.order_updated
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateOrders: (order_updated) => dispatch({ type: actionTypes.ORDERUPDATED, value: order_updated }),
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Users);