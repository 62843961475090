import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actionTypes from './store/action';
import AUX from './hoc/Aux_';
import Layout from './components/Layout/Layout';
import mainbuilder from './containers/mainbuilder/mainbuilder';
import Login from './containers/MainContent/Pages/Login';
import { doCheckUser } from '../src/helpers/ApiHelper';

class App extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      sessionChecked: false
    }
  }

  componentDidMount() {
    doCheckUser().then(response => {
      if (response.status !== 200) {
        localStorage.removeItem('user');
        this.props.authenticated(false);
      } else {
        response.json().then(response => {
          localStorage.setItem('user', JSON.stringify({
            id: response.user.user_id,
            name: response.user.name,
            email: response.user.email,
            superadmin_role: response.user.superadmin_role,
            owner_role: response.user.owner_role
          }));
          this.props.authenticated(true);
        });
      }
      this.setState({ sessionChecked: true })
    });
  }

  render() {
    let mainContent = null;

    if (this.state.sessionChecked) {
      if (this.props.is_authenticated) {
        mainContent = (
          <Layout header={this.props.header} sidebar={this.props.sidebar} footer={this.props.footer} is_authenticated={this.props.is_authenticated}>
            <Switch>
              <Route path='/' component={mainbuilder} />
            </Switch>
          </Layout>
        );
      } else {
        mainContent = (
          <Login />
        );
      }
    }

    return (
      <AUX>
        {mainContent}
      </AUX>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    authenticated: (value) => dispatch({ type: actionTypes.isAuthenticated, value: value }),
  };
}

const mapStateToProps = state => {
  return {
    header: state.ui_red.header,
    sidebar: state.ui_red.sidebar,
    footer: state.ui_red.footer,
    loginpage: state.ui_red.loginpage,
    is_authenticated: state.ui_red.is_authenticated
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
