import React, { Component } from 'react';
import { connect } from 'react-redux';

import AUX from '../../../../hoc/Aux_';
import { doGet } from '../../../../helpers/ApiHelper';
import * as actionTypes from '../../../../store/action';
import DetailedOrder from '../Orders/DetailedOrder';

class DetailedClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      client: null,
      clientLoaded: false,
      orderId: null,
      currentTab: 'detailed_client'
    };
  }

  componentDidMount() {
    doGet(`/clients/${this.props.clientId}`).then(response =>
      this.setState({ client: response }, () => this.setState({ clientLoaded: true }))
    );
  }

  changeCurrentTab(tab) {
    window.scrollTo(0, 0);
    this.setState({ currentTab: tab });
  }

  render() {
    let pageContent = (<div className="spinner"></div>);

    if (this.state.clientLoaded) {
      if (this.state.currentTab === 'detailed_client') {
        pageContent = (
          <React.Fragment>
            <button type="button" className="btn btn-link px-0" onClick={() => this.props.changeCurrentTab('clients')}>Voltar para listagem de clientes</button>
            <div className="row">
              <div className="col-md-12">
                <div className="card m-b-20">
                  <div className="card-body shadow-sm">
                    <h4>{this.state.client.nome}</h4>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="card m-b-20">
                          <div className="card-body shadow-sm">
                            <div className="col-md-12 m-b-20">
                              <h6 className="header-title">Dados Cadastrais</h6>
                            </div>
                            <div className="col-md-12">
                              Email: <strong>{this.state.client.email}</strong>
                              <hr className="my-1"/>
                              {
                                (this.state.client.sexo !== null && this.state.client.sexo !== '') ?
                                  (
                                    <React.Fragment>
                                      Sexo: <strong>{this.state.client.sexo === 'f' ? 'Feminino' : 'Masculino'}</strong>
                                      <hr className="my-1"/>
                                    </React.Fragment>
                                  )
                                : null
                              }
                              Telefone celular: <strong>{this.state.client.telefone_celular}</strong>
                              <hr className="my-1"/>
                              Telefone residencial: <strong>{this.state.client.telefone_principal}</strong>
                              <hr className="my-1"/>
                              Telefone comercial: <strong>{this.state.client.telefone_comercial}</strong>
                              <hr className="my-1" />
                              Grupo: <strong>{this.state.client.grupo_nome}</strong>
                              {
                                (this.state.client.data_nascimento !== null && this.state.client.data_nascimento !== '') ?
                                  (
                                    <React.Fragment>
                                      <hr className="my-1" />
                                      Data Nascimento: <strong>{this.state.client.data_nascimento}</strong>
                                    </React.Fragment>
                                  )
                                  : null
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card m-b-20">
                          <div className="card-body shadow-sm">
                            <div className="col-md-12 m-b-20">
                              <h6 className="header-title">Endereços</h6>
                            </div>
                            <div className="col-md-12">
                              <strong>{this.state.client.nome}</strong><br />
                              {
                                this.state.client.tipo === 'PF' ?
                                  (
                                    <React.Fragment>
                                      CPF: <strong>{this.state.client.cpf}</strong><br />
                                      RG: <strong>{this.state.client.rg}</strong>
                                    </React.Fragment>
                                  )
                                  : (this.state.client.tipo === 'PJ' ?
                                      (
                                        <React.Fragment>
                                          CNPJ: <strong>{this.state.client.cnpj}</strong><br />
                                          Insc. Est.: <strong>{this.state.client.ie}</strong><br />
                                          Razão social: <strong>{this.state.client.razao_social}</strong>
                                        </React.Fragment>
                                      )
                                    : null
                                  )
                              }
                              <hr className="my-1" />
                              <div style={{ maxHeight: '150px', overflowY: this.state.client.enderecos.length > 1 ? 'scroll' : 'unset' }}>
                                {
                                  this.state.client.enderecos.map((endereco, i) => {
                                    return (
                                      <React.Fragment key={endereco.id}>
                                        {i !== 0 ? (<hr/>) : null }
                                        {endereco.endereco}, {endereco.numero} - {endereco.complemento}<br />
                                        {endereco.bairro} - {endereco.cidade} / {endereco.estado}<br />
                                        CEP: {endereco.cep}<br />
                                        {endereco.pais}
                                      </React.Fragment>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card m-b-20">
                          <div className="card-body shadow-sm">
                            <div className="col-md-12 m-b-20">
                              <h6 className="header-title">Pedidos</h6>
                              {
                                this.state.client.pedidos.length > 0 ?
                                  (
                                    <React.Fragment>
                                      <table className="table table-hover">
                                        <thead>
                                          <tr className="table-active">
                                            <th scope="col">ID</th>
                                            <th scope="col">Data</th>
                                            <th scope="col">Situação</th>
                                            <th scope="col">Total</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {
                                            this.state.client.pedidos.map((pedido) => {
                                              return (
                                                <tr key={pedido.id}>
                                                  <td><button type="button" className="btn btn-link" onClick={() => this.setState({ orderId: pedido.id, currentTab: 'detailed_order' })}>#{pedido.id}</button></td>
                                                  <td>{pedido.data_criacao}</td>
                                                  <td>{pedido.situacao}</td>
                                                  <td>{pedido.valor_total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                                </tr>
                                              )
                                            })
                                          }
                                        </tbody>
                                      </table>
                                    </React.Fragment>
                                  )
                                :
                                  (
                                    <p>Não existem pedidos cadastrados.</p>
                                  )
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      } else if (this.state.currentTab === 'detailed_order') {
        pageContent = (
          <DetailedOrder
            changeCurrentTab={this.changeCurrentTab.bind(this)}
            previousTab={'detailed_client'}
            nameTab={'o cliente'}
            orderId={this.state.orderId}
          />
        );
      }
    }

    return (
      <AUX>{pageContent}</AUX>
    );
  }
}

const mapStatetoProps = store => {
  return {
    order_updated: store.ui_red.order_updated
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateOrders: (order_updated) => dispatch({ type: actionTypes.ORDERUPDATED, value: order_updated }),
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(DetailedClient);