import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Pagination } from 'semantic-ui-react';
import { Table, Form, InputGroup, FormControl, Button } from 'react-bootstrap';

import AUX from '../../../../hoc/Aux_';
import { doGet } from '../../../../helpers/ApiHelper';
import * as actionTypes from '../../../../store/action';
import DetailedClient from './DetailedClient';

class Clients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      clients: {
        per_page: 10,
        current_page: 1,
        total_pages: 0,
        entries: []
      },
      term: '',
      sort: {
        column_sort: 'email',
        order: 'asc'
      },
      clientId: null,
      currentTab: 'clients'
    };
  }

  componentDidMount() {
    this.loadClients();
  }

  changeCurrentTab = tab => {
    window.scrollTo(0, 0);
    this.setState({ currentTab: tab });
  }

  loadClients = (page = this.state.clients.current_page, per_page = this.state.clients.per_page) => {
    const term = this.state.term !== '' ? `&term=${this.state.term}` : '';
    if (this.state.clients.per_page !== per_page) page = 1; 

    this.setState({
      loading: true
    }, () => doGet(`/clients?page=${page}&per_page=${per_page}&column_sort=${this.state.sort.column_sort}&order=${this.state.sort.order}${term}`).then(response => this.initialState(response)));
  }

  initialState = responseData => {
    this.setState({
      loading: false,
      clients: responseData
    }, () => {
      if (responseData.out_of_bounds) this.loadClients(1);
    });
  }

  handlePage = (e, { activePage }) => {
    this.loadClients(
      activePage,
      this.state.clients.per_page
    );
  }

  handlePerPageChange = option => {
    const per_page = parseInt(option.target.value);

    if (this.state.clients.per_page === per_page) return;

    this.loadClients(
      this.state.clients.current_page,
      per_page
    );
  }

  sortColumn = column => {
    const order = this.state.sort.order === 'asc' ? 'desc' : 'asc';
    this.setState({
      sort: {
        column_sort: column,
        order: this.state.sort.column_sort === column ? order : 'asc'
      }
    }, () => this.loadClients(1));
  }

  handleSearchChange = option => {
    this.setState({ term: option.target.value });
  }

  render() {
    const iconOrder = this.state.sort.order === 'asc' ? (<span className="mdi mdi-menu-up font-weight-bolder"></span>) : (<span className="mdi mdi-menu-down font-weight-bolder"></span>);
    let clientsContent = (<div className="spinner"></div>);
    const perPageOptions = (
      <>
        <option value="50">Mostrar 50</option>
        <option value="100">Mostrar 100</option>
        <option value="150">Mostrar 150</option>
      </>
    );

    const countResultsTable = (
      <div className="row">
        <div className="col-md-12">
          Mostrando {this.state.clients.offset + 1} para {this.state.clients.last_page ? this.state.clients.total_entries : this.state.clients.current_page * this.state.clients.total_count} de {this.state.clients.total_entries} resultados
        </div>
      </div>
    );
      
    if (this.state.currentTab === 'clients') {
      let tbodyContent = (<tr></tr>);

      if (!this.state.loading) {
        tbodyContent = (
          this.state.clients.entries.map((row) => (
            <tr key={row.id}>
              <th className="align-middle" scope="row">{row.id}</th>
              <td className="align-middle">{row.nome}</td>
              <td className="align-middle">{row.email}</td>
              <td className="align-middle">{row.tipo}</td>
              <td className="align-middle">{row.qtd_pedidos}</td>
              <td className="align-middle">{row.data_criacao}</td>
              <td><button type="button" className="btn btn-primary btn-sm" onClick={() => this.setState({ currentTab: 'detailed_client', clientId: row.id })}><i className="mdi mdi-eye"></i></button></td>
            </tr>
          ))
        );
      }

      clientsContent = (
        <>
          <div className="row mb-2">
            <div className="col-md-4">
              <InputGroup>
                <FormControl
                  placeholder="Buscar cliente..."
                  defaultValue={this.state.term}
                  onChange={this.handleSearchChange.bind(this)}
                />
                <InputGroup.Append>
                  <Button variant="primary" onClick={() => this.loadClients(1)}><i className="mdi mdi-magnify"></i></Button>
                </InputGroup.Append>
              </InputGroup>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-auto" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
              <Form.Control size="sm" as="select" onChange={this.handlePerPageChange.bind(this)} defaultValue={this.state.clients.per_page}>
                {perPageOptions}
              </Form.Control>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card m-b-20">
                <div className="card-body p-0">
                  <Table className="mb-0" hover responsive striped>
                    <thead>
                      <tr>
                        <th className="align-middle" height="60" onClick={() => this.sortColumn('id')} style={{ cursor: 'pointer' }}>
                          ID
                          {this.state.sort.column_sort === 'id' ? iconOrder : (<span style={{ color: '#BBB' }}><i className="mdi mdi-menu-up font-weight-bolder"></i><i className="mdi mdi-menu-down font-weight-bolder"></i></span>)}
                        </th>
                        <th className="align-middle" height="60" onClick={() => this.sortColumn('nome')} style={{ cursor: 'pointer' }}>
                          Nome
                          {this.state.sort.column_sort === 'nome' ? iconOrder : (<span style={{ color: '#BBB' }}><i className="mdi mdi-menu-up font-weight-bolder"></i><i className="mdi mdi-menu-down font-weight-bolder"></i></span>)}
                        </th>
                        <th className="align-middle" height="60" onClick={() => this.sortColumn('email')} style={{ cursor: 'pointer' }}>
                          Email
                          {this.state.sort.column_sort === 'email' ? iconOrder : (<span style={{ color: '#BBB' }}><i className="mdi mdi-menu-up font-weight-bolder"></i><i className="mdi mdi-menu-down font-weight-bolder"></i></span>)}
                        </th>
                        <th className="align-middle" height="60" onClick={() => this.sortColumn('tipo')} style={{ cursor: 'pointer' }}>
                          Tipo
                          {this.state.sort.column_sort === 'tipo' ? iconOrder : (<span style={{ color: '#BBB' }}><i className="mdi mdi-menu-up font-weight-bolder"></i><i className="mdi mdi-menu-down font-weight-bolder"></i></span>)}
                        </th>
                        <th className="align-middle" height="60" onClick={() => this.sortColumn('qtd_pedidos')} style={{ cursor: 'pointer' }}>
                          Pedidos
                          {this.state.sort.column_sort === 'qtd_pedidos' ? iconOrder : (<span style={{ color: '#BBB' }}><i className="mdi mdi-menu-up font-weight-bolder"></i><i className="mdi mdi-menu-down font-weight-bolder"></i></span>)}
                        </th>
                        <th className="align-middle" height="60" onClick={() => this.sortColumn('data_criacao')} style={{ cursor: 'pointer' }}>
                          Data de Criação
                          {this.state.sort.column_sort === 'data_criacao' ? iconOrder : (<span style={{ color: '#BBB' }}><i className="mdi mdi-menu-up font-weight-bolder"></i><i className="mdi mdi-menu-down font-weight-bolder"></i></span>)}
                        </th>
                        <th className="align-middle" height="60"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {tbodyContent}
                    </tbody>
                  </Table>
                </div>
              </div>
              {countResultsTable}
              <div className="row text-center">
                <div className="col-md-12">
                  <Pagination
                    onPageChange={this.handlePage}
                    boundaryRange='1'
                    size='mini'
                    activePage={this.state.clients.current_page}
                    totalPages={this.state.clients.total_pages}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else if (this.state.currentTab === 'detailed_client') {
      clientsContent = (
        <DetailedClient
          changeCurrentTab={this.changeCurrentTab.bind(this)}
          clientId={this.state.clientId}
        />
      );
    }

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            {clientsContent}
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = store => {
  return {
    order_updated: store.ui_red.order_updated
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateOrders: (order_updated) => dispatch({ type: actionTypes.ORDERUPDATED, value: order_updated }),
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Clients);