import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import $ from 'jquery';
import { InsertChart, Dashboard, TrendingUp, ShoppingCart, AccountTree, AssignmentInd, Store, SupervisorAccount, Extension, Payment } from '@material-ui/icons';

class sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Tab: 'index',
      SubTab: '',
      MoreTab: '',
      orders_menu: false,
      products_menu: false
    };

    this.currentUser = JSON.parse(localStorage.getItem('user'));
  }

  setActiveTab = (tab, subtab, moretab, toggleTab, e) => {
    this.setState({
      Tab: tab,
      SubTab: subtab,
      MoreTab: moretab
    });
  }
  
  componentDidMount() {
    this.nowRoutePage();

    $('.button-menu-mobile').on('click', function (event) {
      event.preventDefault();
      $("body").toggleClass("enlarged");
    });
  }

  componentDidUpdate() {   
    this.nowRoutePage();
  }

  nowRoutePage() {
    const now_route = window.location.pathname.split(/[?#]/)[0].substr(1).replace(/_/g, " ");

    $('#now_routing').empty();
    if (now_route === "")
      $('#now_routing').empty();
    else if (now_route === "forecast")
      $('#now_routing').removeClass('capitalize').append("Previsão do mês");
    else if (now_route === "orders")
      $('#now_routing').removeClass('capitalize').append("Pedidos");
    else if (now_route === "orders canceled")
      $('#now_routing').removeClass('capitalize').append("Pedidos cancelados");
    else if (now_route === "campaigns orders")
      $('#now_routing').removeClass('capitalize').append("Pedidos por campanhas");
    else if (now_route === "orders per state")
      $('#now_routing').removeClass('capitalize').append("Pedidos por estados");
    else if (now_route === "products")
      $('#now_routing').removeClass('capitalize').append("Produtos");
    else if (now_route === "selled products")
      $('#now_routing').removeClass('capitalize').append("Produtos vendidos");
    else if (now_route === "top selling products")
      $('#now_routing').removeClass('capitalize').append("Produtos mais vendidos");
    else if (now_route === "clients")
      $('#now_routing').removeClass('capitalize').append("Clientes");
    else if (now_route === "users")
      $('#now_routing').removeClass('capitalize').append("Usuários");
    else
      $('#now_routing').addClass('capitalize').append(now_route);
  }
  
  render() {
    let sidebarContent = null;

    if (this.currentUser.owner_role) {
      sidebarContent = (
        <PerfectScrollbar>
          <div id="sidebar-menu" className="mb-5">
            <ul>
              <li className="menu-title">Incenso Fênix</li>
              <li>
                <Link to="/" className={(this.state.Tab === 'index') ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'index', '', '')}><i><InsertChart /></i> Painel</Link>
              </li>
              <li>
                <Link to="dashboard" className={(this.state.Tab === 'dashboard') ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'dashboard', '', '')}><i><Dashboard /></i> Dashboard</Link>
              </li>
              <li>
                <Link to="forecast" className={this.state.Tab === 'forecast' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'forecast', '', '')}><i><TrendingUp /></i> Previsão</Link>
              </li>
              <li className={this.state.orders_menu ? 'has_sub active-menu nav-active' : 'has_sub'}>
                <Link to="#" onClick={() => this.setState({ orders_menu: !this.state.orders_menu })} className={this.state.Tab === 'orders' ? 'waves-effect active-menu' : 'waves-effect'}><i><ShoppingCart /></i> Pedidos</Link>
                <ul className="list-unstyled" style={{ display: this.state.orders_menu ? 'block' : 'none' }}>
                  <li><Link to="orders" className={this.state.SubTab === 'orders' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'orders', 'orders', '')}>- Todos os pedidos</Link></li>
                  <li><Link to="orders_canceled" className={this.state.SubTab === 'orders_canceled' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'orders', 'orders_canceled', '')}>- Pedidos cancelados</Link></li>
                  <li><Link to="campaigns_orders" className={this.state.SubTab === 'campaigns_orders' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'orders', 'campaigns_orders', '')}>- Pedidos por campanhas</Link></li>
                  <li><Link to="orders_per_state" className={this.state.SubTab === 'orders_per_state' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'orders', 'orders_per_state', '')}>- Pedidos por estados</Link></li>
                </ul>
              </li>
              <li className={this.state.products_menu ? 'has_sub active-menu nav-active' : 'has_sub'}>
                <Link to="#" onClick={() => this.setState({ products_menu: !this.state.products_menu })} className={this.state.Tab === 'products' ? 'waves-effect active-menu' : 'waves-effect'}><i><AccountTree /></i> Produtos</Link>
                <ul className="list-unstyled" style={{ display: this.state.products_menu ? 'block' : 'none' }}>
                  <Link to="products" className={this.state.Tab === 'products' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'products', '', '')}>- Todos os produtos</Link>
                  <Link to="selled_products" className={this.state.Tab === 'selled_products' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'selled_products', '', '')}>- Produtos vendidos</Link>
                  <Link to="top_selling_products" className={this.state.Tab === 'top_selling_products' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'top_selling_products', '', '')}>- Produtos mais vendidos</Link>
                </ul>
              </li>
              <li>
                <Link to="clients" className={this.state.Tab === 'clients' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'clients', '', '')}><i><AssignmentInd /></i> Clientes</Link>
              </li>
            </ul>
          </div>
          <div id="sidebar-menu">
            <ul>
              <li className="menu-title">Configurações</li>
              <li>
                <Link to="/" className={this.state.Tab === 'account' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'account', '', '')}><i><Store /></i> Conta</Link>
              </li>
              <li>
                <Link to="users" className={this.state.Tab === 'users' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'users', '', '')}><i><SupervisorAccount /></i> Usuários</Link>
              </li>
              <li>
                <Link to="/" className={this.state.Tab === 'integrations' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'integrations', '', '')}><i><Extension /></i> Integrações</Link>
              </li>
              <li>
                <Link to="/" className={this.state.Tab === 'subscription' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'subscription', '', '')}><i><Payment /></i> Assinatura</Link>
              </li>
            </ul>
          </div>
          <div className="clearfix"></div>
        </PerfectScrollbar>
      );
    } else {
      sidebarContent = (
        <PerfectScrollbar>
          <div id="sidebar-menu" className="mb-5">
            <ul>
              <li className="menu-title">Incenso Fênix</li>
              <li>
                <Link to="/" className={(this.state.Tab === 'index') ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'index', '', '')}><i><InsertChart /></i> Painel</Link>
              </li>
              <li className={this.state.orders_menu ? 'has_sub active-menu nav-active' : 'has_sub'}>
                <Link to="#" onClick={() => this.setState({ orders_menu: !this.state.orders_menu })} className={this.state.Tab === 'orders' ? 'waves-effect active-menu' : 'waves-effect'}><i><ShoppingCart /></i> Pedidos</Link>
                <ul className="list-unstyled" style={{ display: this.state.orders_menu ? 'block' : 'none' }}>
                  <li><Link to="orders" className={this.state.SubTab === 'orders' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'orders', 'orders', '')}>- Todos os pedidos</Link></li>
                  <li><Link to="orders_canceled" className={this.state.SubTab === 'orders_canceled' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'orders', 'orders_canceled', '')}>- Pedidos cancelados</Link></li>
                </ul>
              </li>
              <li className={this.state.products_menu ? 'has_sub active-menu nav-active' : 'has_sub'}>
                <Link to="#" onClick={() => this.setState({ products_menu: !this.state.products_menu })} className={this.state.Tab === 'products' ? 'waves-effect active-menu' : 'waves-effect'}><i><AccountTree /></i> Produtos</Link>
                <ul className="list-unstyled" style={{ display: this.state.products_menu ? 'block' : 'none' }}>
                  <Link to="products" className={this.state.Tab === 'products' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'products', '', '')}>- Todos os produtos</Link>
                </ul>
              </li>
              <li>
                <Link to="clients" className={this.state.Tab === 'clients' ? 'waves-effect active-menu' : 'waves-effect'} onClick={this.setActiveTab.bind(this, 'clients', '', '')}><i><AssignmentInd /></i> Clientes</Link>
              </li>
            </ul>
          </div>
          <div className="clearfix"></div>
        </PerfectScrollbar>
      );
    }

    return (
      <div className="left side-menu">
        <div className="topbar-left">
          <h5 className="text-white">CIRCULOZEN.COM.BR</h5>
        </div>

        <div className="sidebar-inner slimscrollleft">
          {sidebarContent}
        </div>
      </div>
    );
  }
}

export default withRouter(sidebar);