import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import AUX from '../../../hoc/Aux_';
import * as actionTypes from '../../../store/action';
import Auth from '../../../Auth';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: ''
    }

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleUsernameChange(event) {
    this.setState({ username: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    Auth.authenticate(this.state.username, this.state.password)
      .then(result => {
        if (result) {
          this.props.authenticated();
        } else {
          alert('Login ou senha incorreto');
        }
      })
      .catch(error => {
        if (error.is_success === false) {
          alert('Login ou senha incorreto');
        } else {
          alert('Desculpe, algo deu errado');
        }
      });
  }

  render(){
    return(
      <AUX>
        <div className="accountbg"></div>
        <div className="wrapper-page">
          <div className="card">
            <div className="card-body">
              <h3 className="text-center m-0">
                <Link to="/" className="logo logo-admin"><h3>CIRCULOZEN.COM.BR</h3></Link>
              </h3>
              <div className="p-3">
                <h4 className="font-18 m-b-5 text-center">Bem-vindo!</h4>

                <form className="form-horizontal m-t-30" onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="username">Login</label>
                    <input type="text" className="form-control" id="username" placeholder="E-mail" value={this.state.username} onChange={this.handleUsernameChange} />
                  </div>

                  <div className="form-group">
                    <label htmlFor="userpassword">Senha</label>
                    <input type="password" className="form-control" id="userpassword" placeholder="Senha" value={this.state.password} onChange={this.handlePasswordChange} />
                  </div>

                  <div className="form-group row m-t-20">
                    <div className="col-sm-12">
                      <button className="btn btn-primary btn-block" type="submit">Acessar</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    authenticated: () => dispatch({ type: actionTypes.isAuthenticated, value: true }),
  };
}

export default connect(null, mapDispatchToProps)(Login);
