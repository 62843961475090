import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import AUX from '../../../hoc/Aux_';
import { doGet } from '../../../helpers/ApiHelper';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/action';

class forecast extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastDay: 0,
      currentMonth: '',
      lastMonth: '',
      expectedDays: 0,
      totalOrders: 0,
      totalBilling: 0,
      avgOrdersPerDay: 0,
      avgBillingPerDay: 0,
      ordersForecast: 0,
      billingForecast: 0,
      avgTicket: 0,
      forecastRealizedChart: {
        label: 0,
        forecast: { data: 0 },
        realized: { data: 0 },
        lastMonthRealized: { data: 0 }
      }
    };
  }

  componentDidMount() {
    this.getForecastData();
  }

  componentDidUpdate() {
    if (this.props.order_updated === true) {
      this.getForecastData();
    }
  }

  getForecastData() {
    doGet(`/orders/forecast`).then(response => this.setState({
      lastDay: response['last_day'],
      currentMonth: response['current_month'],
      lastMonth: response['last_month'],
      expectedDays: response['expected_days'],
      totalOrders: response['total_orders'],
      totalBilling: response['total_billing'],
      avgOrdersPerDay: response['avg_orders_per_day'],
      avgBillingPerDay: response['avg_billing_per_day'],
      ordersForecast: response['orders_forecast'],
      billingForecast: response['billing_forecast'],
      avgTicket: response['avg_ticket'],
      forecastRealizedChart: { 
        label: response['forecast_chart']['label'],
        forecast: {
          data: response['forecast_chart']['forecast']['data']
        },
        realized: {
          data: response['forecast_chart']['realized']['data']
        },
        lastMonthRealized: {
          data: response['forecast_chart']['last_month_realized']['data']
        },
      }
    }, () => this.props.UpdateOrders(false)))
    .catch(() => {
      this.setState({
        lastDay: 0,
        currentMonth: '',
        lastMonth: '',
        expectedDays: 0,
        totalOrders: 0,
        totalBilling: 0,
        avgOrdersPerDay: 0,
        avgBillingPerDay: 0,
        ordersForecast: 0,
        billingForecast: 0,
        avgTicket: 0,
        forecastRealizedChart: {
          label: 0,
          forecast: { data: 0 },
          realized: { data: 0 },
          lastMonthRealized: { data: 0 }
        }
      });
    });
  }

  render() {

    const forecastRealizedChart = (
      <Line
        data={{
          labels: this.state.forecastRealizedChart.label,
          datasets: [
            {
              label: 'Realizado',
              data: this.state.forecastRealizedChart.realized.data,
              borderColor: 'rgba(0, 150, 136, 1)',
              backgroundColor: 'rgba(74, 193, 142, .3)',
              lineTension: 0,
              order: 1
            }, {
              label: 'Previsão',
              data: this.state.forecastRealizedChart.forecast.data,
              borderColor: 'rgba(74, 193, 142, .5)',
              backgroundColor: 'rgba(59, 195, 233, 0)',
              lineTension: 0,
              order: 2
            }, {
              label: 'Mês anterior',
              data: this.state.forecastRealizedChart.lastMonthRealized.data,
              borderColor: 'rgba(224, 224, 224, 1)',
              backgroundColor: 'rgba(224, 224, 224, .3)',
              lineTension: 0,
              order: 3
            }
          ]
        }}
        options={{
          maintainAspectRatio: false,
          tooltips: {
            bodyFontSize: 14,
            displayColors: false,
            titleAlign: 'center',
            bodyAlign: 'center'
          },
          scales: {
            yAxes: [{
              ticks: {
                min: 0
              }
            }]
          }
        }}
        type='line'
      />
    );

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">

            <div className="row">
              <div className="col-md-4">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row" style={{ minHeight: '350px' }}>
                      <div className="col-md-12">
                        <h4 className="header-title">Mês atual <small className="text-secondary">{this.state.currentMonth}</small></h4>
                        <p>
                          Pedidos no total: <strong>{this.state.totalOrders}</strong><br />
                          Faturamento total: <strong>{this.state.totalBilling.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong><br />
                          Ticket médio: <strong>{this.state.avgTicket.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                        </p>
                        <hr />
                        <h4 className="header-title">Média por dia <small className="text-secondary">{this.state.lastDay} dias</small></h4>
                        <p>
                          Pedidos por dia: <strong>{this.state.avgOrdersPerDay}</strong><br />
                          Faturamento por dia: <strong>{this.state.avgBillingPerDay.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                        </p>
                        <hr />
                        <h4 className="header-title">Previsão <small className="text-secondary">{this.state.expectedDays} dias</small></h4>
                        <p>
                          Pedidos previstos: <strong>{this.state.ordersForecast}</strong><br />
                          Faturamento previsto: <strong>{this.state.billingForecast.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div style={{ minHeight: '350px' }}>
                      {forecastRealizedChart}
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = store => {
  return {
    order_updated: store.ui_red.order_updated
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateOrders: (order_updated) => dispatch({ type: actionTypes.ORDERUPDATED, value: order_updated }),
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(forecast);