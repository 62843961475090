import React, { Component } from 'react';
import { Bar, Line } from 'react-chartjs-2';

class Order extends Component {
  render() {
    let labelsForBarOrdersChart = null;
    let dataForBarOrdersChart = null;
    let stepSizeForBarOrdersChart = null;
    let chartBar = null;
    let chartLine = null;
    let chartForecast = null;

    switch (this.props.chartTypeSelected) {
      case 'chart_per_hour':
        labelsForBarOrdersChart = this.props.ordersPerHourChart.labels;
        dataForBarOrdersChart = this.props.ordersPerHourChart.datasets;
        stepSizeForBarOrdersChart = 1;
        chartLine = null;
        chartForecast = null;
        chartBar = (
          <Bar
            width={100}
            height={300}
            data={{
              labels: labelsForBarOrdersChart,
              datasets: [
                {
                  label: false,
                  data: dataForBarOrdersChart,
                  backgroundColor: 'rgba(59, 195, 233, 1)'
                }
              ]
            }}
            options={{
              maintainAspectRatio: false,
              tooltips: {
                bodyFontSize: 14,

                displayColors: false,
                titleAlign: 'center',
                bodyAlign: 'center',
                callbacks: {
                  label: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                  }
                }
              },
              legend: { display: false },
              scales: {
                yAxes: [{
                  ticks: {
                    callback: value => value,
                    min: 0,
                    stepSize: stepSizeForBarOrdersChart
                  }
                }]
              }
            }}
            type='bar'
          />
        );
        break;
      case 'chart_accumulated':
        labelsForBarOrdersChart = this.props.ordersAccumulated.labels;
        dataForBarOrdersChart = this.props.ordersAccumulated.datasets;
        stepSizeForBarOrdersChart = 10;
        chartBar = null;
        chartForecast = null;
        chartLine = (
          <Line
            width={100}
            height={300}
            data={{
              labels: labelsForBarOrdersChart,
              datasets: [
                {
                  label: false,
                  data: dataForBarOrdersChart,
                  fill: false,
                  borderColor: 'rgba(59, 195, 233, 1)',
                  lineTension: 0,
                  borderWidth: 1,
                  pointBackgroundColor: 'rgba(59, 195, 233, 1)'
                }
              ]
            }}
            options={{
              maintainAspectRatio: false,
              tooltips: {
                bodyFontSize: 14,
                displayColors: false,
                titleAlign: 'center',
                bodyAlign: 'center',
                callbacks: {
                  label: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                  }
                }
              },
              legend: { display: false },
              scales: {
                yAxes: [{
                  ticks: {
                    callback: value => value,
                    min: 0,
                    stepSize: stepSizeForBarOrdersChart
                  }
                }]
              }
            }}
            type='line'
          />
        );
        break;
      case 'chart_forecast':
        chartBar = null;
        chartLine = null;
        chartForecast = (
          <Line
            width={100}
            height={300}
            data={{
              labels: this.props.ordersForecast.label,
              datasets: [
                {
                  label: 'Realizado',
                  data: this.props.ordersForecast.realized.data,
                  borderColor: 'rgba(59, 195, 233, 1)',
                  backgroundColor: 'rgba(59, 195, 233, .15)',
                  lineTension: 0,
                  order: 1
                },
                {
                  label: 'Aprovados',
                  hidden: true,
                  data: this.props.ordersForecast.realizedApproveds.data,
                  borderColor: 'rgba(97, 183, 147, 1)',
                  backgroundColor: 'rgba(97, 183, 147, 0)',
                  lineTension: 0,
                  order: 2
                },
                {
                  label: 'Aguardando Pagamento',
                  hidden: true,
                  data: this.props.ordersForecast.realizedWaitingPayments.data,
                  borderColor: 'rgba(224, 164, 88, 1)',
                  backgroundColor: 'rgba(74, 193, 142, 0)',
                  lineTension: 0,
                  order: 3
                },
                {
                  label: 'Previsão',
                  data: this.props.ordersForecast.forecast.data,
                  borderColor: 'rgba(59, 195, 233, .5)',
                  backgroundColor: 'rgba(59, 195, 233, 0)',
                  lineTension: 0,
                  order: 4
                },
                {
                  label: 'Anterior',
                  data: this.props.ordersForecast.beforeRealized.data,
                  borderColor: 'rgba(224, 224, 224, 1)',
                  backgroundColor: 'rgba(224, 224, 224, .3)',
                  lineTension: 0,
                  order: 5
                }
              ]
            }}
            options={{
              maintainAspectRatio: false,
              tooltips: {
                bodyFontSize: 14,
                displayColors: false,
                titleAlign: 'center',
                bodyAlign: 'center'
              },
              scales: {
                yAxes: [{
                  ticks: {
                    min: 0
                  }
                }]
              }
            }}
            type='line'
          />
        );
        break;
      default:
        break;
    }

    return (
      <React.Fragment>
        {chartBar}
        {chartLine}
        {chartForecast}
      </React.Fragment>
    );
  }
}

export default Order;