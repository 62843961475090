import * as actionTypes from '../action';
const initialState = {
  header: true,
  sidebar: true,
  footer: true,
  loginpage: false,
  order_updated: false,
  is_authenticated: false
}

const reducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.HEADER:
      return{
        ...state,
        header: !state.header
      };
    case actionTypes.SIDEBAR:
      return{
        ...state,
        sidebar: !state.sidebar
      };
    case actionTypes.FOOTER:
      return{
        ...state,
        footer: !state.footer
      };
    case actionTypes.LOGINPAGE:
      return{
        ...state,
        loginpage: !state.loginpage
      };
    case actionTypes.ORDERUPDATED:
      return {
        ...state,
        order_updated: action.value
      };
    case actionTypes.isAuthenticated:
      return {
        ...state,
        is_authenticated: action.value
      };
    default:
      return state;
  }
}

export default reducer;