import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pt-br';

import AUX from '../../../../hoc/Aux_';
import { doGet } from '../../../../helpers/ApiHelper';
import * as actionTypes from '../../../../store/action';

class DetailedOrder extends Component {
  constructor(props) {
    moment().locale('pt-br');

    super(props);

    this.state = {
      order: null,
      orderHistories: [],
      orderLoaded: false,
      orderHistoriesLoaded: false
    };
  }

  componentDidMount() {
    doGet(`/orders/${this.props.orderId}`).then(response =>
      this.setState({ order: response, orderLoaded: true }, () =>
        doGet(`/order_situation_histories/${this.props.orderId}`).then(response =>
          this.setState({ orderHistories: response, orderHistoriesLoaded: true })
        )
      )
    );
  }

  noteContent = () => {
    if (this.state.orderHistories.length > 0) {
      if (this.state.orderHistories[this.state.orderHistories.length - 1].obs === null) {
        return (
          <div className="text-center form-control p-0" style={{ backgroundColor: '#E9ECEF', opacity: '1' }}>
            {this.state.orderHistories[0].obs}
          </div>
        );
      }
    }

    return (
      <div className="text-center form-control p-0" style={{ backgroundColor: '#E9ECEF', opacity: '1' }}>
        <h6 className="text-muted">Nenhuma observação</h6>
      </div>
    );
  }

  render() {
    const loader = (<div className="py-3"><div className="spinner"></div></div>);
    let orderContent = (<div className="mx-auto spinner"></div>);

    if (this.state.orderLoaded) {
      let form_payment_name = '';
      let invoice_url = null;

      if (this.state.order.pagamento != null) {
        form_payment_name = this.state.order.pagamento.forma_pagamento_nome;

        if (this.state.order.pagamento.forma_pagamento_id === 27) invoice_url = (<p className="mb-0"><a rel="noopener noreferrer" href={this.state.order.boleto_url} target="_blank">Visualizar boleto</a></p>);
      }

      orderContent = (
        <React.Fragment>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-12">
                <div className="card m-b-20">
                  <div className="card-body shadow-sm">
                    <h6>Detalhe do pedido</h6>
                    <hr />
                    <div className="form-group row">
                      <label htmlFor="order-id" className="col-sm-2 col-form-label">Número do pedido</label>
                      <div className="col-sm-4">
                        <input className="form-control" type="text" id="order-id" value={this.props.orderId} readOnly/>
                      </div>

                      <label htmlFor="order-campaign" className="col-sm-2 col-form-label">Campanha</label>
                      <div className="col-sm-4">
                        <input className="form-control" type="text" id="order-campaign" value={this.state.order.utm_campaign} readOnly />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label htmlFor="order-client-name" className="col-sm-2 col-form-label">Nome do cliente</label>
                      <div className="col-sm-10">
                        <input className="form-control" type="text" id="order-client-name" value={this.state.order.cliente.nome} readOnly />
                      </div>
                    </div>
                    <hr />
                    <div className="form-group row">
                      <label htmlFor="order-client-email" className="col-sm-2 col-form-label">E-mail</label>
                      <div className="col-sm-4">
                        <input className="form-control" type="text" id="order-client-email" value={this.state.order.cliente.email} readOnly />
                      </div>

                      <label htmlFor="order-client-phone" className="col-sm-2 col-form-label">Telefone residencial</label>
                      <div className="col-sm-4">
                        <input className="form-control" type="text" id="order-client-phone" value={this.state.order.cliente.telefone_principal} readOnly />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label htmlFor="order-client-cellphone" className="col-sm-2 col-form-label">Telefone celular</label>
                      <div className="col-sm-4">
                        <input className="form-control" type="text" id="order-client-cellphone" value={this.state.order.cliente.telefone_celular} readOnly />
                      </div>

                      <label htmlFor="order-client-commercial-phone" className="col-sm-2 col-form-label">Telefone comercial</label>
                      <div className="col-sm-4">
                        <input className="form-control" type="text" id="order-client-commercial-phone" value={this.state.order.cliente.telefone_comercial} readOnly />
                      </div>
                    </div>
                    <hr />
                    <div className="form-group row">
                      <label htmlFor="order-address" className="col-sm-2 col-form-label">Endereço</label>
                      <div className="col-sm-10">
                        <input className="form-control" type="text" id="order-address" value={`${this.state.order.endereco_entrega_endereco}, ${this.state.order.endereco_entrega_numero} - ${this.state.order.endereco_entrega_complemento}`} readOnly />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label htmlFor="order-cep" className="col-sm-2 col-form-label">CEP</label>
                      <div className="col-sm-4">
                        <input className="form-control" type="text" id="order-cep" value={this.state.order.endereco_entrega_cep} readOnly />
                      </div>

                      <label htmlFor="order-bairro" className="col-sm-2 col-form-label">Bairro</label>
                      <div className="col-sm-4">
                        <input className="form-control" type="text" id="order-bairro" value={this.state.order.endereco_entrega_bairro} readOnly />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label htmlFor="order-city" className="col-sm-2 col-form-label">Cidade</label>
                      <div className="col-sm-4">
                        <input className="form-control" type="text" id="order-city" value={this.state.order.endereco_entrega_cidade} readOnly />
                      </div>

                      <label htmlFor="order-state" className="col-sm-2 col-form-label">Estado</label>
                      <div className="col-sm-4">
                        <input className="form-control" type="text" id="order-state" value={this.state.order.endereco_entrega_estado} readOnly />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card m-b-20">
                  <div className="card-body shadow-sm">
                    <h6>Produtos</h6>
                    <hr />
                    {
                      this.state.order.produtos.map((product) => {
                        return (
                          <React.Fragment key={product.id}>
                            <div className="row">
                              <div className="col-md-6">
                                <img src={product.imagem} alt={product.nome} className="pr-3" />
                                <strong>{product.nome}</strong>
                              </div>
                              <div className="col-md-4 text-center">
                                SKU: {product.sku}
                                <br />
                                Disponibilidade: {product.disponibilidade === 0 ? 'Imediata' : product.disponibilidade === 1 ? `${product.disponibilidade} dia útil` : `${product.disponibilidade} dias úteis`}
                              </div>
                              <div className="col-md-2 text-right">
                                <strong>{product.preco_venda.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                                <br />
                                Qtd. {product.quantidade}
                              </div>
                            </div>
                            <hr />
                          </React.Fragment>
                        )
                      })
                    }
                  </div>
                  <div className="py-3 px-3 shadow-sm text-right" style={{ backgroundColor: '#F0F0F0' }}>
                    Desconto{this.state.order.valor_desconto === 0 ? '' : this.state.order.pagamento.forma_pagamento_id === 27 ? (<React.Fragment> via <strong>Boleto Bancário</strong></React.Fragment>) : (<React.Fragment> via cupom <strong>{this.state.order.cupom_codigo}</strong></React.Fragment>)}: {this.state.order.valor_desconto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    <hr className="my-2" />
                    {this.state.order.envio === null ?
                        ''
                      :
                        (<>
                          Envio via {this.state.order.envio.forma_envio_nome} ({this.state.order.envio.forma_envio_tipo}): {parseFloat(this.state.order.envio.valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                          <hr className="my-2" />
                        </>)
                    }
                    <strong>Total: {this.state.order.valor_total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                    <hr className="my-2" />
                    Parcelado em: {this.state.order.pagamento.numero_parcelas != null ? `${this.state.order.pagamento.numero_parcelas}x ${parseFloat(this.state.order.pagamento.valor) === this.state.order.valor_total ? 'sem juros' : ''}` : '-' }
                    <hr className="my-2" />
                    Valor da parcela: {this.state.order.pagamento.valor_parcela != null ? this.state.order.pagamento.valor_parcela.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}
                    <hr className="my-2" />
                    <strong>Total parcelado: {parseFloat(this.state.order.pagamento.valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="row">
              <div className="col-md-12">
                <div className="card m-b-20">
                  <div className="card-body shadow-sm">
                    <h6>Situação do pedido</h6>
                    {this.state.order.situacao}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card m-b-20">
                  <div className="card-body shadow-sm">
                    <h6>Pagamento</h6>
                    <div className="text-center">
                      <div className="form-control p-0" style={{ backgroundColor: '#E9ECEF', opacity: '1' }}>
                        <h6>{form_payment_name}</h6>
                      </div>
                      <strong>Id da transação: {this.state.order.pagamento.transacao_id}</strong>
                      {invoice_url}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card m-b-20">
                  <div className="card-body shadow-sm">
                    <h6>Envio</h6>
                    <div className="text-center">
                      {this.state.order.envio === null ?
                        ''
                        :
                        (<>
                          <div className="form-control p-0" style={{ backgroundColor: '#E9ECEF', opacity: '1' }}>
                            <h6>{this.state.order.envio.forma_envio_nome}</h6>
                          </div>
                          <strong>Código de rastreio: {this.state.order.envio.objeto}</strong>
                          <br />
                          Prazo de entrega: {this.state.order.envio.prazo !== 1 ? `${this.state.order.envio.prazo} dias úteis` : `${this.state.order.envio.prazo} dia útil`} após a confirmação do pagamento
                        </>)
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card m-b-20">
                  <div className="card-body shadow-sm">
                    <h6>Observações</h6>
                    {
                      !this.state.orderHistoriesLoaded ?
                        loader
                      :
                        this.noteContent()
                    }
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card m-b-20">
                  <div className="card-body shadow-sm">
                    <h6>Histórico</h6>
                    {
                      !this.state.orderHistoriesLoaded ?
                        (
                          <React.Fragment>
                            <hr />
                            {loader}
                          </React.Fragment>
                        )
                      :
                        this.state.orderHistories.map((order_history, i) => {
                          if (i === 0) {
                            return (
                              <React.Fragment key={order_history.id}>
                                <hr />
                                <strong>{moment.parseZone(order_history.data).format('L - LT')}</strong>
                                <br />
                                {order_history.situacao_anterior_nome}
                                <br />
                                <span className="text-muted">{`${order_history.alterado_por_nome} (${order_history.alterado_por})`}</span>
                                <hr />
                                <strong>{moment.parseZone(order_history.data).format('L - LT')}</strong>
                                <br />
                                {order_history.situacao_nome}
                                <br />
                                <span className="text-muted">{`${order_history.alterado_por_nome} (${order_history.alterado_por})`}</span>
                              </React.Fragment>
                            )
                          } else {
                            return (
                              <React.Fragment key={order_history.id}>
                                <hr />
                                <strong>{moment.parseZone(order_history.data).format('L - LT')}</strong>
                                <br />
                                {order_history.situacao_nome}
                                <br />
                                <span className="text-muted">{`${order_history.alterado_por_nome} (${order_history.alterado_por})`}</span>
                              </React.Fragment>
                            )
                          }
                        })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <AUX>
        <button type="button" className="btn btn-link px-0" onClick={() => this.props.changeCurrentTab(this.props.previousTab)}>Voltar para {this.props.nameTab}</button>
        <div className="row">
          {orderContent}
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = store => {
  return {
    order_updated: store.ui_red.order_updated
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateOrders: (order_updated) => dispatch({ type: actionTypes.ORDERUPDATED, value: order_updated }),
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(DetailedOrder);