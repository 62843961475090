import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

class ProductsTable extends Component {
  render() {
    const iconOrder = this.props.sort.order === 'asc' ? (<span className="mdi mdi-menu-up font-weight-bolder"></span>) : (<span className="mdi mdi-menu-down font-weight-bolder"></span>);
    let tbodyContent = (<tr></tr>);

    if (!this.props.loading) {
      tbodyContent = (
        this.props.productsEntries.map((row) => (
          <tr key={row.id}>
            <th className="align-middle" scope="row">{row.id}</th>
            <td className="align-middle">{row.nome}</td>
            <td className="align-middle">{row.sku}</td>
            <td className="align-middle">{row.preco_custo.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
            <td className="align-middle">{row.preco_cheio.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
            <td className="align-middle">{row.preco_promocional.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
            <td className="align-middle">{row.produto_ativado}</td>
            <td><button type="button" className="btn btn-primary btn-sm" onClick={() => this.props.showDetailedProduct(row.id)}><i className="mdi mdi-eye"></i></button></td>
          </tr>
        ))
      );
    }

    return (
      <Table className="mb-0" hover responsive>
        <thead>
          <tr>
            <th className="align-middle" height="60" onClick={() => this.props.sortColumn('id')} style={{ cursor: 'pointer' }}>
              #
              {this.props.sort.column_sort === 'id' ? iconOrder : (<span style={{ color: '#BBB' }}><i className="mdi mdi-menu-up font-weight-bolder"></i><i className="mdi mdi-menu-down font-weight-bolder"></i></span>)}
            </th>
            <th className="align-middle" height="60" onClick={() => this.props.sortColumn('nome')} style={{ cursor: 'pointer' }}>
              Nome
              {this.props.sort.column_sort === 'nome' ? iconOrder : (<span style={{ color: '#BBB' }}><i className="mdi mdi-menu-up font-weight-bolder"></i><i className="mdi mdi-menu-down font-weight-bolder"></i></span>)}
            </th>
            <th className="align-middle" height="60" onClick={() => this.props.sortColumn('sku')} style={{ cursor: 'pointer' }}>
              SKU
              {this.props.sort.column_sort === 'sku' ? iconOrder : (<span style={{ color: '#BBB' }}><i className="mdi mdi-menu-up font-weight-bolder"></i><i className="mdi mdi-menu-down font-weight-bolder"></i></span>)}
            </th>
            <th className="align-middle" height="60" onClick={() => this.props.sortColumn('produto_precos.custo')} style={{ cursor: 'pointer' }}>
              Preço custo
              {this.props.sort.column_sort === 'produto_precos.custo' ? iconOrder : (<span style={{ color: '#BBB' }}><i className="mdi mdi-menu-up font-weight-bolder"></i><i className="mdi mdi-menu-down font-weight-bolder"></i></span>)}
            </th>
            <th className="align-middle" height="60" onClick={() => this.props.sortColumn('produto_precos.cheio')} style={{ cursor: 'pointer' }}>
              Preço cheio
              {this.props.sort.column_sort === 'produto_precos.cheio' ? iconOrder : (<span style={{ color: '#BBB' }}><i className="mdi mdi-menu-up font-weight-bolder"></i><i className="mdi mdi-menu-down font-weight-bolder"></i></span>)}
            </th>
            <th className="align-middle" height="60" onClick={() => this.props.sortColumn('produto_precos.promocional')} style={{ cursor: 'pointer' }}>
              Preço promocional
              {this.props.sort.column_sort === 'produto_precos.promocional' ? iconOrder : (<span style={{ color: '#BBB' }}><i className="mdi mdi-menu-up font-weight-bolder"></i><i className="mdi mdi-menu-down font-weight-bolder"></i></span>)}
            </th>
            <th className="align-middle" height="60" onClick={() => this.props.sortColumn('ativo')} style={{ cursor: 'pointer' }}>
              Ativo
              {this.props.sort.column_sort === 'ativo' ? iconOrder : (<span style={{ color: '#BBB' }}><i className="mdi mdi-menu-up font-weight-bolder"></i><i className="mdi mdi-menu-down font-weight-bolder"></i></span>)}
            </th>
            <th className="align-middle" height="60"></th>
          </tr>
        </thead>
        <tbody>
          {tbodyContent}
        </tbody>
      </Table>
    );
  }
}

export default ProductsTable;