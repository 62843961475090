const Auth = {
  isAuthenticated: false,
  authenticate(email, password) {
    return new Promise((resolve, reject) => {
      let endpoint = `${process.env.REACT_APP_BACKEND_URL}/sign_in`;
      fetch(endpoint, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        redirect: 'follow',
        referrer: 'no-referrer',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'user': { 'email': email, 'password': password } })
      }).then(result => {
          result.json().then(response => {
            if (response.code === 200) {
              localStorage.setItem('user', JSON.stringify({
                id: response.user.user_id,
                name: response.user.name,
                email: response.user.email,
                superadmin_role: response.user.superadmin_role,
                owner_role: response.user.owner_role
              }));
              this.isAuthenticated = true;
              resolve(Auth.getAuth());
            } else {
              this.isAuthenticated = false;
              localStorage.removeItem('user');
              reject(response);
            }
          });
        })
        .catch(error => {
          this.isAuthenticated = false;
          reject(error);
        });
    });
  },
  signout(userId = 0) {
    return new Promise((resolve, reject) => {
      let endpoint = `${process.env.REACT_APP_BACKEND_URL}/sign_out`;
      if (userId !== 0) {
        fetch(endpoint, {
          method: 'DELETE',
          mode: 'cors',
          cache: 'no-cache',
          redirect: 'follow',
          referrer: 'no-referrer',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' }
        }).then(e => {
            if (e.ok) {
              localStorage.removeItem('user');
              this.isAuthenticated = false;
              resolve(e);
            } else {
              reject(e);
            }
          })
          .catch(e => console.log("error::", e));
      } else {
        reject('userId não informado');
      }
    });
  },
  getAuth() {
    return this.isAuthenticated;
  }
};

export default Auth;