import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Pagination } from 'semantic-ui-react';
import { Form, InputGroup, FormControl, Button } from 'react-bootstrap';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import '../../../../assets/css/custom-daterangepicker.css';

import AUX from '../../../../hoc/Aux_';
import { doGet } from '../../../../helpers/ApiHelper';
import * as actionTypes from '../../../../store/action';
import OrdersTable from './Components/OrdersTable';
import DetailedOrder from './DetailedOrder';

class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      orders: {
        current_page: 1,
        first_page: true,
        last_page: false,
        per_page: 50,
        total_count: 0,
        total_pages: 0,
        total_entries: 0,
        offset: 0,
        entries: []
      },
      term: '',
      sort: {
        column_sort: 'id',
        order: 'desc'
      },
      startDate: '0000-01-01',
      endDate: moment().format('YYYY-MM-DD'),
      initialValue: `  /  /   -  ${moment().format('DD/MM/YYYY')}`,
      orderIdSelected: null,
      currentTab: 'orders'
    };
  }

  componentDidMount() {
    this.loadOrders();
  }

  changeCurrentTab = tab => {
    window.scrollTo(0, 0);
    this.setState({ currentTab: tab });
  }

  showDetailedOrder = orderId => {
    this.setState({ orderId: orderId, currentTab: 'detailed_order' });
  }

  loadOrders = (page = this.state.orders.current_page, per_page = this.state.orders.per_page, start_date = this.state.startDate, end_date = this.state.endDate) => {
    const term = this.state.term !== '' ? `&term=${this.state.term}` : '';
    if (this.state.orders.per_page !== per_page) page = 1;

    this.setState({
      loading: true
    }, () => doGet(`/orders?page=${page}&per_page=${per_page}&start_date=${start_date}&end_date=${end_date}&column_sort=${this.state.sort.column_sort}&order=${this.state.sort.order}${term}`).then(response => this.initialState(response)));
  }

  initialState = responseData => {
    this.setState({
      loading: false,
      orders: responseData
    }, () => {
      if (responseData.out_of_bounds) this.loadOrders(1);
    });
  }

  handlePage = (e, { activePage }) => {
    this.loadOrders(
      activePage,
      this.state.orders.per_page,
      this.state.startDate,
      this.state.endDate
    );
  }

  handlePerPageChange = option => {
    const per_page = parseInt(option.target.value);

    if (this.state.orders.per_page === per_page) return;

    this.loadOrders(
      this.state.orders.current_page,
      per_page,
      this.state.startDate,
      this.state.endDate
    );
  }

  handleDateRangePickerCallback = (start, end) => {
    this.setState({
      startDate: start.format('YYYY-MM-DD'),
      endDate: end.format('YYYY-MM-DD')
    });
  }

  handleApply = (ev, picker) => {
    const interval = picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY');
    this.setState({ initialValue: interval })
    picker.element.val(interval);
    this.loadOrders(
      this.state.orders.current_page,
      this.state.orders.per_page,
      this.state.startDate,
      this.state.endDate
    );
  }

  sortColumn = column => {
    const order = this.state.sort.order === 'asc' ? 'desc' : 'asc';
    this.setState({
      sort: {
        column_sort: column,
        order: this.state.sort.column_sort === column ? order : 'asc'
      }
    }, () => this.loadOrders(1));
  }

  handleSearchChange = option => {
    this.setState({ term: option.target.value });
  }

  render() {
    let pageContent = (<div className="spinner"></div>);
    const perPageOptions = (
      <>
        <option value="50">Mostrar 50</option>
        <option value="100">Mostrar 100</option>
        <option value="150">Mostrar 150</option>
      </>
    );

    const countResultsTable = (
      <div className="row">
        <div className="col-md-12">
          Mostrando {this.state.orders.offset + 1} para {this.state.orders.last_page ? this.state.orders.total_entries : this.state.orders.current_page * this.state.orders.total_count} de {this.state.orders.total_entries} resultados
        </div>
      </div>
    );

    const dateRangePickerSettings = {
      autoUpdateInput: false,
      alwaysShowCalendars: true,
      showDropdowns: true,
      showCustomRangeLabel: false,
      opens: 'left',
      startDate: moment().toDate(),
      endDate: moment().toDate(),
      maxYear: parseInt(moment().format('Y')),
      ranges: {
        'Hoje': [moment().toDate(), moment().toDate()],
        'Ontem': [
          moment().subtract(1, 'days').toDate(),
          moment().subtract(1, 'days').toDate(),
        ],
        'Semana atual': [
          moment().startOf('week'),
          moment().endOf('week'),
        ],
        'Últimos 7 dias': [
          moment().subtract(6, 'days').toDate(),
          moment().toDate(),
        ],
        'Últimos 30 dias': [
          moment().subtract(29, 'days').toDate(),
          moment().toDate(),
        ],
        'Mês atual': [
          moment().startOf('month').toDate(),
          moment().endOf('month').toDate(),
        ],
        'Mês passado': [
          moment().subtract(1, 'month').startOf('month').toDate(),
          moment().subtract(1, 'month').endOf('month').toDate(),
        ],
      },
      locale: {
        applyLabel: 'Aplicar',
        cancelLabel: 'Cancelar',
        daysOfWeek: [
          'D',
          'S',
          'T',
          'Q',
          'Q',
          'S',
          'S'
        ]
      }
    }

    if (this.state.currentTab === 'orders') {
      pageContent = (
        <>
          <div className="row mb-2">
            <div className="col-md-4">
              <InputGroup>
                <FormControl
                  placeholder="Buscar pedido..."
                  defaultValue={this.state.term}
                  onChange={this.handleSearchChange.bind(this)}
                  onKeyPress={event => { if (event.key === 'Enter') this.loadOrders(1) } }
                />
                <InputGroup.Append>
                  <Button variant="primary" onClick={() => this.loadOrders(1)}><i className="mdi mdi-magnify"></i></Button>
                </InputGroup.Append>
              </InputGroup>
            </div>
            <div className="col-md-3 offset-md-5">
              <DateRangePicker
                initialSettings={dateRangePickerSettings}
                onCallback={this.handleDateRangePickerCallback}
                onApply={this.handleApply}
              >
                <input type="text" className="form-control" value={this.state.initialValue} />
              </DateRangePicker>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-auto" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
              <Form.Control size="sm" as="select" onChange={this.handlePerPageChange.bind(this)} defaultValue={this.state.orders.per_page}>
                {perPageOptions}
              </Form.Control>
            </div>
          </div>
          
          <div className="row mb-2">
            <div className="col-md-auto">
              <h6>Listando {this.state.orders.total_entries} pedidos <small className="text-muted">Página {this.state.orders.offset + 1} de {this.state.orders.total_pages}</small></h6>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card m-b-20">
                <div className="card-body p-0">
                  <OrdersTable
                    loading={this.state.loading}
                    showDetailedOrder={this.showDetailedOrder.bind(this)}
                    ordersEntries={this.state.orders.entries}
                    sort={this.state.sort}
                    sortColumn={this.sortColumn.bind(this)}
                  />
                </div>
              </div>
              {countResultsTable}
              <div className="row text-center">
                <div className="col-md-12">
                  <Pagination
                    onPageChange={this.handlePage}
                    boundaryRange='1'
                    size='mini'
                    activePage={this.state.orders.current_page}
                    totalPages={this.state.orders.total_pages}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else if (this.state.currentTab === 'detailed_order') {
      pageContent = (
        <DetailedOrder
          changeCurrentTab={this.changeCurrentTab.bind(this)}
          previousTab={'orders'}
          nameTab={'os pedidos'}
          orderId={this.state.orderId}
        />
      );
    }

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            {pageContent}
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = store => {
  return {
    order_updated: store.ui_red.order_updated
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateOrders: (order_updated) => dispatch({ type: actionTypes.ORDERUPDATED, value: order_updated }),
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Orders);