import React, { Component } from 'react';
import { PieChart } from 'react-easy-chart';
import { Bar, Line, Pie } from 'react-chartjs-2';
import Select from 'react-select';
import { Button, ButtonGroup } from 'reactstrap';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt';
import moment from 'moment';
import { connect } from 'react-redux';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-datepicker/dist/react-datepicker.css';

import * as actionTypes from '../../../store/action';
import AUX from '../../../hoc/Aux_';
import { doGet } from '../../../helpers/ApiHelper';

class Dashboard extends Component {
  constructor(props) {
    registerLocale('pt', pt);
    setDefaultLocale('pt');

    super(props);

    this.state = {
      billingChart: {},
      billingPerHourChart: {},
      billingPerDayChart: {},
      billingPerWeekChart: {},
      transactionsChart: {},
      transactionsPerHourChart: {},
      transactionsPerDayChart: {},
      transactionsPerWeekChart: {},
      ordersByWeekChart: {},
      avgCanceledOrdersByWeekChart: {},
      avgOrdersByHourChart: {},
      averageTicketChart: {},
      salesPerStateChart: {},
      allOrders: { total: 0, count: 0, percentage: 0 },
      placedOrders: { total: 0, count: 0, percentage: 0 },
      approvedOrders: { total: 0, count: 0, percentage: 0 },
      canceledOrders: { total: 0, count: 0, percentage: 0 },
      awaitingPayments: { total: 0, count: 0, percentage: 0 },
      ordersByClients: { labels: 0, newClients: 0, recurrentClients: 0 },
      billingChartSelected: 'chart_per_day',
      transactionsChartSelected: 'chart_per_day',
      optionSelected: { value: 1, label: `Mês atual` },
      startDate: moment().startOf('month').toDate(), 
      endDate: moment().endOf('month').toDate()
    };
  }

  componentDidMount() {
    this.handleSearch();
  }

  handleSearch(filters = true) {
    let params = '';

    if (filters === true) {
      const start_date = moment(this.state.startDate).format('YYYY-MM-DD');
      const end_date = moment(this.state.endDate).format('YYYY-MM-DD');
      params = `?start_date=${start_date}&end_date=${end_date}`
    }

    doGet(`/orders/billing${params}`).then(response => this.setState({
      billingChartSelected: 'chart_per_day',
      transactionsChartSelected: 'chart_per_day',
      billingChart: { labels: Object.keys(response['billing']), datasets: Object.values(response['billing']) },
      billingPerHourChart: response['billing_per_hour'] === false ? false : { labels: Object.keys(response['billing_per_hour']), datasets: Object.values(response['billing_per_hour']) },
      billingPerDayChart: response['billing_per_day'] === false ? false : { labels: Object.keys(response['billing_per_day']), datasets: Object.values(response['billing_per_day']) },
      billingPerWeekChart: response['billing_per_week'] === false ? false : { labels: Object.keys(response['billing_per_week']), datasets: Object.values(response['billing_per_week']) },
      transactionsChart: { labels: Object.keys(response['transactions']), datasets: Object.values(response['transactions']) },
      transactionsPerHourChart: response['transactions_per_hour'] === false ? false : { labels: Object.keys(response['transactions_per_hour']), datasets: Object.values(response['transactions_per_hour']) },
      transactionsPerDayChart: response['transactions_per_day'] === false ? false : { labels: Object.keys(response['transactions_per_day']), datasets: Object.values(response['transactions_per_day']) },
      transactionsPerWeekChart: response['transactions_per_week'] === false ? false : { labels: Object.keys(response['transactions_per_week']), datasets: Object.values(response['transactions_per_week']) },
      ordersByClients: { labels: Object.keys(response['orders_by_client']), newClients: Object.values(response['orders_by_client']).map(value => value.new_clients), recurrentClients: Object.values(response['orders_by_client']).map(value => value.recurrent_clients) },
      ordersByWeekChart: { labels: Object.keys(response['orders_by_week']), datasets: Object.values(response['orders_by_week']) },
      avgCanceledOrdersByWeekChart: { labels: Object.keys(response['avg_canceled_orders_by_week']), datasets: Object.values(response['avg_canceled_orders_by_week']) },
      avgOrdersByHourChart: { labels: Object.keys(response['avg_orders_by_hour']), datasets: Object.values(response['avg_orders_by_hour']) },
      averageTicketChart: { labels: Object.keys(response['average_ticket']), datasets: Object.values(response['average_ticket']) },
      salesPerStateChart: { labels: Object.keys(response['sales_per_state']), datasets: Object.values(response['sales_per_state']) }
    }))
    .catch(() => {
      this.setState({
        billingChart: {},
        billingPerHourChart: {},
        billingPerDayChart: {},
        billingPerWeekChart: {},
        transactionsChart: {},
        transactionsPerHourChart: {},
        transactionsPerDayChart: {},
        transactionsPerWeekChart: {},
        ordersByWeekChart: {},
        avgCanceledOrdersByWeekChart: {},
        avgOrdersByHourChart: {},
        averageTicketChart: {},
        salesPerStateChart: {},
        ordersByClients: { labels: 0, newClients: 0, recurrentClients: 0 },
        billingChartSelected: 'chart_per_day',
        transactionsChartSelected: 'chart_per_day',
        optionSelected: { value: 1, label: `Mês atual` },
        startDate: moment().startOf('month').toDate(),
        endDate: moment().endOf('month').toDate()
      });
    });

    doGet(`/orders/status${params}`).then(response => this.setState({
      allOrders: { total: response['all_orders']['total'], count: response['all_orders']['count'], percentage: response['all_orders']['percentage'] },
      placedOrders: { total: response['placed_orders']['total'], count: response['placed_orders']['count'], percentage: response['placed_orders']['percentage'] },
      approvedOrders: { total: response['approved_orders']['total'], count: response['approved_orders']['count'], percentage: response['approved_orders']['percentage'] },
      canceledOrders: { total: response['canceled_orders']['total'], count: response['canceled_orders']['count'], percentage: response['canceled_orders']['percentage'] },
      awaitingPayments: { total: response['awaiting_payments']['total'], count: response['awaiting_payments']['count'], percentage: response['awaiting_payments']['percentage'] }
    }))
    .catch(() => {
      this.setState({
        allOrders: { total: 0, count: 0, percentage: 0 },
        placedOrders: { total: 0, count: 0, percentage: 0 },
        approvedOrders: { total: 0, count: 0, percentage: 0 },
        canceledOrders: { total: 0, count: 0, percentage: 0 },
        awaitingPayments: { total: 0, count: 0, percentage: 0 }
      });
    });
  }

  handleStart = date => {
    this.setState({ startDate: date });
  }
  handleEnd = date => {
    this.setState({ endDate: date });
  }

  optionChanged = option => {
    this.setState({ optionSelected: option }, () => {
      switch (option.value) {
        case -1:
          this.setState({ startDate: new Date(), endDate: new Date() }, () => {this.handleSearch()});
          break;
        case 0:
          this.setState({ startDate: moment().subtract(1, 'days').toDate(), endDate: moment().subtract(1, 'days').toDate() }, () => {this.handleSearch()});
          break;
        case 1:
          this.setState({ startDate: moment().startOf('month').toDate(), endDate: moment().endOf('month').toDate() }, () => {this.handleSearch()});
          break;
        case 2:
          this.setState({ startDate: moment().startOf('month').subtract(1, 'months').toDate(), endDate: moment().endOf('month').subtract(1, 'months').toDate() }, () => {this.handleSearch()});
          break;
        case 3:
          this.setState({ startDate: moment().startOf('month').subtract(3, 'months').toDate(), endDate: moment().endOf('month').subtract(1, 'months').toDate() }, () => {this.handleSearch()});
          break;
        case 6:
          this.setState({ startDate: moment().startOf('month').subtract(6, 'months').toDate(), endDate: moment().endOf('month').subtract(1, 'months').toDate() }, () => {this.handleSearch()});
          break;
        case 9:
          this.setState({ startDate: moment().startOf('month').subtract(9, 'months').toDate(), endDate: moment().endOf('month').subtract(1, 'months').toDate() }, () => {this.handleSearch()});
          break;
        case 12:
          this.setState({ startDate: moment().startOf('month').subtract(12, 'months').toDate(), endDate: moment().endOf('month').subtract(1, 'months').toDate() }, () => {this.handleSearch()});
          break;
        default:
          break;
      }
    });
  }

  onChartChange(chart, value) {
    if (chart === 'billingChart')
      this.setState({ billingChartSelected: value });
    
    if (chart === 'transactionsChart')
      this.setState({ transactionsChartSelected: value });
  }

  componentDidUpdate() {
    if (this.props.order_updated === true) {
      this.setState({
        billingChartSelected: 'chart_per_day',
        transactionsChartSelected: 'chart_per_day',
        optionSelected: { value: 1, label: `Mês atual` },
        startDate: moment().startOf('month').toDate(),
        endDate: moment().endOf('month').toDate()
      }, () => { this.handleSearch(); this.props.UpdateOrders(false); });
    }
  }

  render() {
    const intervalOptions = [
        { value: -1, label: `Hoje` },
        { value: 0, label: `Ontem` },
        { value: 1, label: `Mês atual` },
        { value: 2, label: `Mês anterior` },
        { value: 3, label: `Últimos 3 meses` },
        { value: 6, label: `Últimos 6 meses` },
        { value: 9, label: `Últimos 9 meses` },
        { value: 12, label: `Últimos 12 meses` }
      ];

    let labelsForBarBillingPeriodChart = null;
    let dataForBarBillingPeriodChart = null;
    let stepSizeForBarBillingPeriodChart = null;

    switch (this.state.billingChartSelected) {
      case 'chart_per_hour':
        labelsForBarBillingPeriodChart = this.state.billingPerHourChart.labels;
        dataForBarBillingPeriodChart = this.state.billingPerHourChart.datasets;
        stepSizeForBarBillingPeriodChart = 500;
        break;
      case 'chart_per_day':
        labelsForBarBillingPeriodChart = this.state.billingPerDayChart.labels;
        dataForBarBillingPeriodChart = this.state.billingPerDayChart.datasets;
        stepSizeForBarBillingPeriodChart = 2500;
        break;
      case 'chart_per_week':
        labelsForBarBillingPeriodChart = this.state.billingPerWeekChart.labels;
        dataForBarBillingPeriodChart = this.state.billingPerWeekChart.datasets;
        stepSizeForBarBillingPeriodChart = 20000;
        break;
      default:
        labelsForBarBillingPeriodChart = this.state.billingChart.labels;
        dataForBarBillingPeriodChart = this.state.billingChart.datasets;
        stepSizeForBarBillingPeriodChart = 40000;
        break;
    }

    const barBillingPeriodChart = (
      <Bar
        data={{
          labels: labelsForBarBillingPeriodChart,
          datasets: [
            {
              label: false,
              data: dataForBarBillingPeriodChart,
              backgroundColor: 'rgba(0, 158, 115, 1)'
            }
          ]
        }}
        options={{
          maintainAspectRatio: true,
          tooltips: {
            bodyFontSize: 14,
            
            displayColors: false,
            titleAlign: 'center',
            bodyAlign: 'center',
            callbacks: {
              label: function (tooltipItem, data) {
                return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
              }
            }
          },
          legend: { display: false },
          scales: {
            yAxes: [{
              ticks: { 
                callback: value => value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
                min: 0,
                stepSize: stepSizeForBarBillingPeriodChart
              }
            }]
          }
        }}
        type='bar'
      />
    );

    let labelsForBarTransactionsPeriodChart = null;
    let dataForBarTransactionsPeriodChart = null;
    let stepSizeForBarTransactionsPeriodChart = null;

    switch (this.state.transactionsChartSelected) {
      case 'chart_per_hour':
        labelsForBarTransactionsPeriodChart = this.state.transactionsPerHourChart.labels;
        dataForBarTransactionsPeriodChart = this.state.transactionsPerHourChart.datasets;
        stepSizeForBarTransactionsPeriodChart = 1;
        break;
      case 'chart_per_day':
        labelsForBarTransactionsPeriodChart = this.state.transactionsPerDayChart.labels;
        dataForBarTransactionsPeriodChart = this.state.transactionsPerDayChart.datasets;
        stepSizeForBarTransactionsPeriodChart = 10;
        break;
      case 'chart_per_week':
        labelsForBarTransactionsPeriodChart = this.state.transactionsPerWeekChart.labels;
        dataForBarTransactionsPeriodChart = this.state.transactionsPerWeekChart.datasets;
        stepSizeForBarTransactionsPeriodChart = 100;
        break;
      default:
        labelsForBarTransactionsPeriodChart = this.state.transactionsChart.labels;
        dataForBarTransactionsPeriodChart = this.state.transactionsChart.datasets;
        stepSizeForBarTransactionsPeriodChart = 200;
        break;
    }

    const barTransactionsPeriodChart = (
      <Bar
        data={{
          labels: labelsForBarTransactionsPeriodChart,
          datasets: [
            {
              label: false,
              data: dataForBarTransactionsPeriodChart,
              backgroundColor: 'rgba(59, 195, 233, 1)'
            }
          ]
        }}
        options={{
          maintainAspectRatio: true,
          tooltips: {
            bodyFontSize: 14,
            
            displayColors: false,
            titleAlign: 'center',
            bodyAlign: 'center',
            callbacks: {
              label: function (tooltipItem, data) {
                return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              }
            }
          },
          legend: { display: false },
          scales: {
            yAxes: [{
              ticks: { 
                callback: value => value,
                min: 0,
                stepSize: stepSizeForBarTransactionsPeriodChart
              }
            }]
          }
        }}
        type='bar'
      />
    );

    let placedOrdersPercentage = null;
    if (this.state.placedOrders.percentage > 1)
      placedOrdersPercentage = (<span className="pull-right text-success"><i className="fa fa-caret-up m-r-5"></i>{this.state.placedOrders.percentage}%</span>);
    else if (this.state.placedOrders.percentage === 0)
      placedOrdersPercentage = (<span className="pull-right text-primary"><i className="fa fa-circle m-r-5"></i>{this.state.placedOrders.percentage}%</span>);
    else
      placedOrdersPercentage = (<span className="pull-right text-danger"><i className="fa fa-caret-down m-r-5"></i>{this.state.placedOrders.percentage}%</span>);

    const placedOrdersChart = (
      <div className="col-md-6 col-xl-3">
        <div className="mini-stat widget-chart-sm clearfix bg-white">
          <PieChart
            size={70}
            data={[
              // { key: 'A', value: this.state.canceledOrders.count, color: '#ea553d' },
              { key: 'B', value: this.state.awaitingPayments.count, color: '#3bc3e9' },
              { key: 'C', value: this.state.approvedOrders.count, color: '#4ac18e' },
            ]}
          />

          <div className="mini-stat-info text-right">
            <span className="counter text-primary">{this.state.placedOrders.count.toLocaleString('pt-br', { style: 'decimal', currency: 'BRL' })}</span>
            Pedidos Realizados
          </div>
          <div className="clearfix"></div>
          <p className=" mb-0 m-t-20 text-muted"><strong>Total:</strong> {this.state.placedOrders.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} {placedOrdersPercentage}</p>
        </div>
      </div>
    );

    let approvedOrdersPercentage = null;
    if (this.state.approvedOrders.percentage > 0)
      approvedOrdersPercentage = (<span className="pull-right text-success"><i className="fa fa-caret-up m-r-5"></i>{this.state.approvedOrders.percentage}%</span>);
    else if (this.state.approvedOrders.percentage === 0)
      approvedOrdersPercentage = (<span className="pull-right text-primary"><i className="fa fa-circle m-r-5"></i>{this.state.approvedOrders.percentage}%</span>);
    else
      approvedOrdersPercentage = (<span className="pull-right text-danger"><i className="fa fa-caret-down m-r-5"></i>{this.state.approvedOrders.percentage}%</span>);

    const approvedOrdersChart = (
      <div className="col-md-6 col-xl-3">
        <div className="mini-stat widget-chart-sm clearfix bg-white">
          <PieChart
            size={70}
            data={[
              { key: 'A', value: this.state.canceledOrders.count, color: '#f2f2f2' },
              { key: 'B', value: this.state.awaitingPayments.count, color: '#f2f2f2' },
              { key: 'C', value: this.state.approvedOrders.count, color: '#4ac18e' },
            ]}
          />

          <div className="mini-stat-info text-right">
            <span className="counter text-success">{this.state.approvedOrders.count.toLocaleString('pt-br', { style: 'decimal', currency: 'BRL' })}</span>
            Pedidos Aprovados
          </div>
          <div className="clearfix"></div>
          <p className=" mb-0 m-t-20 text-muted"><strong>Total:</strong> {this.state.approvedOrders.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} {approvedOrdersPercentage}</p>
        </div>
      </div>
    );

    const awaitingPaymentsChart = (
      <div className="col-md-6 col-xl-3">
        <div className="mini-stat widget-chart-sm clearfix bg-white">
          <PieChart
            size={70}
            data={[
              { key: 'A', value: this.state.canceledOrders.count, color: '#f2f2f2' },
              { key: 'B', value: this.state.awaitingPayments.count, color: '#3bc3e9' },
              { key: 'C', value: this.state.approvedOrders.count, color: '#f2f2f2' },
            ]}
          />

          <div className="mini-stat-info text-right">
            <span className="counter text-info">{this.state.awaitingPayments.count.toLocaleString('pt-br', { style: 'decimal', currency: 'BRL' })}</span>
            Aguardando Pagamento
          </div>
          <div className="clearfix"></div>
          <p className=" mb-0 m-t-20 text-muted"><strong>Total:</strong> {this.state.awaitingPayments.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
        </div>
      </div>
    );

    let canceledOrdersPercentage = null;
    if (this.state.canceledOrders.percentage > 0)
      canceledOrdersPercentage = (<span className="pull-right text-danger"><i className="fa fa-caret-down m-r-5"></i>{this.state.canceledOrders.percentage}%</span>);
    else if (this.state.canceledOrders.percentage === -100)
      canceledOrdersPercentage = (<span className="pull-right text-primary"><i className="fa fa-circle m-r-5"></i>0%</span>);
    else
      canceledOrdersPercentage = (<span className="pull-right text-success"><i className="fa fa-caret-up m-r-5"></i>{this.state.canceledOrders.percentage}%</span>);

    const canceledOrdersChart = (
      <div className="col-md-6 col-xl-3">
        <div className="mini-stat widget-chart-sm clearfix bg-white">
          <PieChart
            size={70}
            data={[
              { key: 'A', value: this.state.canceledOrders.count, color: '#ea553d' },
              { key: 'B', value: this.state.awaitingPayments.count, color: '#f2f2f2' },
              { key: 'C', value: this.state.approvedOrders.count, color: '#f2f2f2' },
            ]}
          />

          <div className="mini-stat-info text-right">
            <span className="counter text-danger">{this.state.canceledOrders.count.toLocaleString('pt-br', { style: 'decimal', currency: 'BRL' })}</span>
            Pedidos Cancelados
          </div>
          <div className="clearfix"></div>
          <p className=" mb-0 m-t-20 text-muted"><strong>Total:</strong> {this.state.canceledOrders.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} {canceledOrdersPercentage}</p>
        </div>
      </div>
    );

    const barOrdersByClientsChart = (
      <Bar
        type='bar'
        data={{
          labels: this.state.ordersByClients.labels,
          datasets: [
            {
              label: 'Clientes novos',
              backgroundColor: 'rgba(127, 255, 0, 1)',
              data: this.state.ordersByClients.newClients
            },
            {
              label: 'Clientes recorrentes',
              backgroundColor: 'rgba(100, 149, 237, 1)',
              data: this.state.ordersByClients.recurrentClients
            }
          ]
        }}
        options={{
          maintainAspectRatio: false,
          tooltips: {
            bodyFontSize: 14,
            displayColors: false,
            titleAlign: 'center',
            bodyAlign: 'center'
          },
          scales: {
            yAxes: [{
              ticks: {
                min: 0
              }
            }]
          }
        }}
      />
    );

    const averageTicketChart = (
      <Line
        data={{
          labels: this.state.averageTicketChart.labels,
          datasets: [
            {
              label: false,
              data: this.state.averageTicketChart.datasets,
              borderColor: 'rgba(0, 150, 136, 1)',
              backgroundColor: 'rgba(255, 255, 255, 0)',
              lineTension: 0
            }
          ]
        }}
        options={{
          maintainAspectRatio: true,
          tooltips: {
            bodyFontSize: 14,
            displayColors: false,
            titleAlign: 'center',
            bodyAlign: 'center',
            callbacks: {
              label: function (tooltipItem, data) {
                return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
              }
            }
          },
          legend: { display: false },
          scales: {
            yAxes: [{
              ticks: {
                callback: value => value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
                min: 0,
                stepSize: 50
              }
            }]
          }
        }}
        type='line'
      />
    );

    const ordersByWeekChart = (
      <Bar
        data={{
          labels: this.state.ordersByWeekChart.labels,
          datasets: [
            {
              label: false,
              data: this.state.ordersByWeekChart.datasets,
              backgroundColor: 'rgb(32, 81, 93)'
            }
          ]
        }}
        options={{
          maintainAspectRatio: false,
          tooltips: {
            bodyFontSize: 14,

            displayColors: false,
            titleAlign: 'center',
            bodyAlign: 'center'
          },
          legend: { display: false },
          scales: {
            yAxes: [{
              ticks: {
                min: 0
              }
            }]
          }
        }}
        type='bar'
      />
    );

    const avgCanceledOrdersByWeekChart = (
      <Bar
        data={{
          labels: this.state.avgCanceledOrdersByWeekChart.labels,
          datasets: [
            {
              label: false,
              data: this.state.avgCanceledOrdersByWeekChart.datasets,
              backgroundColor: 'rgb(209, 96, 80)'
            }
          ]
        }}
        options={{
          maintainAspectRatio: false,
          tooltips: {
            bodyFontSize: 14,

            displayColors: false,
            titleAlign: 'center',
            bodyAlign: 'center'
          },
          legend: { display: false },
          scales: {
            yAxes: [{
              ticks: {
                min: 0
              }
            }]
          }
        }}
        type='bar'
      />
    );

    const avgOrdersByHourChart = (
      <Bar
        data={{
          labels: this.state.avgOrdersByHourChart.labels,
          datasets: [
            {
              label: false,
              data: this.state.avgOrdersByHourChart.datasets,
              backgroundColor: 'rgb(129, 160, 48)'
            }
          ]
        }}
        options={{
          maintainAspectRatio: false,
          tooltips: {
            bodyFontSize: 14,

            displayColors: false,
            titleAlign: 'center',
            bodyAlign: 'center',
          },
          legend: { display: false },
          scales: {
            yAxes: [{
              ticks: {
                min: 0
              }
            }]
          }
        }}
        type='bar'
      />
    );

    const salesPerStateChart = (
      <Pie
        data={{
          labels: this.state.salesPerStateChart.labels,
          datasets: [
            {
              data: this.state.salesPerStateChart.datasets,
              backgroundColor: ['#D92332', '#0477BF', '#F2B705', '#F2D335', '#F24BA7', '#32D97A', '#E1F25C', '#F27B35', '#E67676', '#6F7C6F', '#AC6D6D', '#00A500', '#7C626F', '#C9C7C8', '#F2A6B7', '#003CFF', '#3B99BE', '#741F00', '#FDE9B4', '#579CC7', '#00665C', '#F24949', '#A89D9D', '#3FA689', '#F2DEA0', '#F29D7E', '#73615F']
            }
          ]
        }}
        options={{
          responsive: true,
          aspectRatio: .5,
          tooltips: {
            bodyFontSize: 14,
            displayColors: false,
            titleAlign: 'center',
            bodyAlign: 'center',
          },
          legend: { display: false }
        }}
        type='pie'
      />
    );

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">

            <div className="form-group">
              <label>Período</label>
              <div className="row">
                <div className="col-md-2">
                  <Select
                    options={intervalOptions}
                    value={this.state.optionSelected}
                    onChange={this.optionChanged}
                    clearable={false}
                  />
                </div>
                <div className="col-md-2">
                  <DatePicker
                    selectsStart
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={this.state.startDate}
                    onChange={this.handleStart}
                    placeholderText="Início"
                    minDate={new Date(2014, 0, 1)}
                    maxDate={new Date()}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    showMonthDropdown
                    showYearDropdown
                  />
                </div>

                <div className="col-md-2">
                  <DatePicker
                    selectsEnd
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={this.state.endDate}
                    onChange={this.handleEnd}
                    placeholderText="Fim"
                    minDate={this.state.startDate}
                    maxDate={new Date()}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    showMonthDropdown
                    showYearDropdown
                  />
                </div>
                <button type="button" className="btn btn-primary" onClick={() => this.handleSearch()}>Buscar</button>
              </div>
            </div>

            <div className="row">
              {placedOrdersChart}
              {approvedOrdersChart}
              {awaitingPaymentsChart}
              {canceledOrdersChart}
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row mt-0 m-b-30" >
                      <div className="col-md-12">
                        <h4 className="header-title">Faturamento</h4>
                      </div>
                      <div className="col-md-6 text-right">
                        <div className="btn-group" role="group">
                          <ButtonGroup>
                            <Button color="blue-grey" active={this.state.billingChartSelected === "chart_per_hour" ? true : false} disabled={this.state.billingPerHourChart === false ? true : false} onClick={() => this.onChartChange('billingChart', 'chart_per_hour')}>Hora </Button>{' '}
                            <Button color="blue-grey" active={this.state.billingChartSelected === "chart_per_day" ? true : false} disabled={this.state.billingPerDayChart === false ? true : false} onClick={() => this.onChartChange('billingChart', 'chart_per_day')}>Dia </Button>{' '}
                            <Button color="blue-grey" active={this.state.billingChartSelected === "chart_per_week" ? true : false} disabled={this.state.billingPerWeekChart === false ? true : false} onClick={() => this.onChartChange('billingChart', 'chart_per_week')}>Semana </Button>{' '}
                            <Button color="blue-grey" active={this.state.billingChartSelected === "chart_per_month" ? true : false} onClick={() => this.onChartChange('billingChart', 'chart_per_month')}>Mês </Button>{' '}
                          </ButtonGroup>
                        </div>
                      </div>
                    </div>
                    <div style={{ minHeight: '350px' }}>
                      {barBillingPeriodChart}
                    </div>
                  </div>
                </div>
              </div>
 
              <div className="col-md-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row mt-0 m-b-30" >
                      <div className="col-md-12">
                        <h4 className="header-title">Pedidos</h4>
                      </div>
                      <div className="col-md-6 text-right">
                        <div className="btn-group" role="group" aria-label="Basic example">
                          <ButtonGroup>
                            <Button color="blue-grey" active={this.state.transactionsChartSelected === "chart_per_hour" ? true : false} disabled={this.state.transactionsPerHourChart === false ? true : false} onClick={() => this.onChartChange('transactionsChart', 'chart_per_hour')}>Hora </Button>{' '}
                            <Button color="blue-grey" active={this.state.transactionsChartSelected === "chart_per_day" ? true : false} disabled={this.state.transactionsPerDayChart === false ? true : false} onClick={() => this.onChartChange('transactionsChart', 'chart_per_day')}>Dia </Button>{' '}
                            <Button color="blue-grey" active={this.state.transactionsChartSelected === "chart_per_week" ? true : false} disabled={this.state.transactionsPerWeekChart === false ? true : false} onClick={() => this.onChartChange('transactionsChart', 'chart_per_week')}>Semana </Button>{' '}
                            <Button color="blue-grey" active={this.state.transactionsChartSelected === "chart_per_month" ? true : false} onClick={() => this.onChartChange('transactionsChart', 'chart_per_month')}>Mês </Button>{' '}
                          </ButtonGroup>
                        </div>
                      </div>
                    </div>
                    <div style={{ minHeight: '350px' }}>
                      {barTransactionsPeriodChart}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row mt-0 m-b-30" >
                      <div className="col-md-6">
                        <h4 className="header-title">Vendas</h4>
                      </div>
                    </div>
                    <div style={{ minHeight: '350px' }}>
                      {barOrdersByClientsChart}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row mt-0 m-b-30" >
                      <div className="col-md-6">
                        <h4 className="header-title">Ticket médio</h4>
                      </div>
                    </div>
                    <div style={{ minHeight: '350px' }}>
                      {averageTicketChart}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row mt-0 m-b-30" >
                      <div className="col-md-12">
                        <h4 className="header-title">Média de pedidos por dia da semana</h4>
                      </div>
                    </div>
                    <div style={{ minHeight: '200px' }}>
                      {ordersByWeekChart}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row mt-0 m-b-30" >
                      <div className="col-md-12">
                        <h4 className="header-title">Média de pedidos por hora</h4>
                      </div>
                    </div>
                    <div style={{ minHeight: '200px' }}>
                      {avgOrdersByHourChart}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row mt-0 m-b-30" >
                      <div className="col-md-12">
                        <h4 className="header-title">Média de pedidos cancelados por dia da semana</h4>
                      </div>
                    </div>
                    <div style={{ minHeight: '200px' }}>
                      {avgCanceledOrdersByWeekChart}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row mt-0 m-b-30" >
                      <div className="col-md-12">
                        <h4 className="header-title">Vendas por Estado</h4>
                      </div>
                    </div>
                    <div style={{ minHeight: '300px' }}>
                      {salesPerStateChart}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = store => {
  return {
    order_updated: store.ui_red.order_updated
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateOrders: (order_updated) => dispatch({ type: actionTypes.ORDERUPDATED, value: order_updated }),
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Dashboard);