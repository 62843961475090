import React, { Component } from 'react';
import { connect } from 'react-redux';

import AUX from '../../../../hoc/Aux_';
import { doGet, doPut } from '../../../../helpers/ApiHelper';
import * as actionTypes from '../../../../store/action';

class EditUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userLoaded: false,
      currentTab: 'edit_user',
      changePassword: false,
      user: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        superadmin_role: 'false',
        owner_role: 'false',
        active: 'true'
      }
    };
  }

  componentDidMount() {
    doGet(`/users/${this.props.userId}`).then(response =>
      this.setState(prevState => ({
        user: {
          ...prevState.user,
          name: response.name,
          email: response.email,
          superadmin_role: response.superadmin_role.toString(),
          owner_role: response.owner_role.toString(),
          active: response.active.toString()
        }
      }), () => this.setState({ userLoaded: true }))
    );
  }

  changeCurrentTab(tab) {
    window.scrollTo(0, 0);
    this.setState({ currentTab: tab });
  }

  handleNameChange = event => {
    const value = event.target.value;

    this.setState(prevState => ({ user: { ...prevState.user, name: value } }));
  }

  setSuperadminRole = event => {
    const value = event.target.value;
    if (this.state.user.superadmin_role === value) return;

    this.setState(prevState => ({ user: { ...prevState.user, superadmin_role: value } }));
  }

  setOwnerRole = event => {
    const value = event.target.value;
    if (this.state.user.owner_role === value) return;

    this.setState(prevState => ({ user: { ...prevState.user, owner_role: value } }));
  }

  handleEmailChange = event => {
    const value = event.target.value;

    this.setState(prevState => ({ user: { ...prevState.user, email: value } }));
  }

  handleActiveChange = event => {
    const value = event.target.value;

    this.setState(prevState => ({ user: { ...prevState.user, active: value } }));
  }

  handlePwdChange = event => {
    const value = event.target.value;

    this.setState(prevState => ({ user: { ...prevState.user, password: value } }));
  }

  handlePwdConfirmationChange = event => {
    const value = event.target.value;

    this.setState(prevState => ({ user: { ...prevState.user, password_confirmation: value } }));
  }

  handleSubmit = event => {
    event.preventDefault();

    if (this.state.selectedProduct !== null) {  
      let params = {
        user: {
          name: this.state.user.name,
          email: this.state.user.email,
          superadmin_role: this.state.user.superadmin_role,
          owner_role: this.state.user.owner_role,
          active: this.state.user.active
        }
      }

      if (this.state.changePassword) {
        if (this.state.user.password !== this.state.user.password_confirmation) {
          alert("As senhas devem ser iguais.");
          return;
        }

        Object.keys(params).map(key => {
          params[key].password = this.state.user.password_confirmation;
          params[key].password_confirmation = this.state.user.password_confirmation;
          return true;
        });
      }

      doPut(`/account/${this.props.userId}`, params).then(response => {
        if (response.status === 200) {
          alert(`Usuário ${this.state.user.name} - ${this.state.user.email} atualizado com sucesso!`);
          this.props.changeCurrentTab('users');
        }
        else alert(`Erro ao atualizar o usuário!`);
      });
    }
  }

  handleChangePassword = () => {
    this.setState({ changePassword: !this.state.changePassword }, () => {
      if (this.state.changePassword === false) {
        this.setState(prevState => ({
          user: {
            ...prevState.user,
            password: '',
            password_confirmation: ''
          }
        }))
      }
    });
  }

  render() {
    let pageContent = (<div className="mt-5 spinner"></div>);

    if (this.state.userLoaded) {
      pageContent = (
        <React.Fragment>
          <button type="button" className="btn btn-link px-0" onClick={() => this.props.changeCurrentTab('users')}>Voltar para listagem de usuários</button>
          <div className="row">
            <div className="col-md-12">
              <div className="card m-b-20">
                <div className="card-body shadow-sm">
                  <h4>Atualizar usuário</h4>
                  <form className="row" onSubmit={this.handleSubmit.bind(this)}>
                    <div className="col-md-8 offset-md-2">
                      <div className="form-group">
                        <label htmlFor="name">Nome</label>
                        <input type="text" className="form-control" id="name" placeholder="Nome do usuário" value={this.state.user.name} onChange={this.handleNameChange.bind(this)} required />
                      </div>
                      <label htmlFor="superadmin_role">Superadmin</label>
                      <div className="form-group" onChange={this.setSuperadminRole.bind(this)}>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="superadminRadioOptions" id="superadmin_true" value="true" checked={this.state.user.superadmin_role === 'true' ? true : false} />
                          <label className="form-check-label" style={{ fontWeight: 'lighter' }} htmlFor="superadmin_true">Sim</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="superadminRadioOptions" id="superadmin_false" value="false" checked={this.state.user.superadmin_role === 'false' ? true : false} />
                          <label className="form-check-label" style={{ fontWeight: 'lighter' }} htmlFor="superadmin_false">Não</label>
                        </div>
                      </div>
                      <label htmlFor="owner_role">Owner</label>
                      <div className="form-group" onChange={this.setOwnerRole.bind(this)}>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="ownerRadioOptions" id="owner_true" value="true" checked={this.state.user.owner_role === 'true' ? true : false} />
                          <label className="form-check-label" style={{ fontWeight: 'lighter' }} htmlFor="owner_true">Sim</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="ownerRadioOptions" id="owner_false" value="false" checked={this.state.user.owner_role === 'false' ? true : false} />
                          <label className="form-check-label" style={{ fontWeight: 'lighter' }} htmlFor="owner_false">Não</label>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">E-mail</label>
                        <input type="email" className="form-control" id="email" placeholder="E-mail" value={this.state.user.email} onChange={this.handleEmailChange.bind(this)} required />
                      </div>
                      <label htmlFor="active">Status</label>
                      <div className="form-group" onChange={this.handleActiveChange.bind(this)}>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="userStatusOptions" id="active" value="true" checked={this.state.user.active === 'true' ? true : false} />
                          <label className="form-check-label" style={{ fontWeight: 'lighter' }} htmlFor="active">Ativo</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="userStatusOptions" id="inactive" value="false" checked={this.state.user.active === 'false' ? true : false} />
                          <label className="form-check-label" style={{ fontWeight: 'lighter' }} htmlFor="inactive">Inativo</label>
                        </div>
                      </div>
                      <button type="button" className={`btn ${!this.state.changePassword ? "btn-primary" : "btn-danger"}`} onClick={this.handleChangePassword.bind(this)}>{!this.state.changePassword ? `Alterar a senha do usuário` : `Cancelar alteração de senha`}</button>
                      <div style={{ display: this.state.changePassword ? 'block' : 'none' }}>
                        <div className="form-group">
                          <label htmlFor="password">Nova senha</label>
                          <input type="password" className="form-control" id="password" value={this.state.user.password} onChange={this.handlePwdChange.bind(this)} required={this.state.changePassword ? true : false} />
                        </div>
                        <div className="form-group">
                          <label htmlFor="password_confirmation">Confirmar nova senha</label>
                          <input type="password" className="form-control" id="password_confirmation" value={this.state.user.password_confirmation} onChange={this.handlePwdConfirmationChange.bind(this)} required={this.state.changePassword ? true : false} />
                        </div>
                      </div>
                      <div className="form-group">
                        <button type="submit" className="btn btn-primary px-5 float-right">Atualizar</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <AUX>
        {pageContent}
      </AUX>
    );
  }
}

const mapStatetoProps = store => {
  return {
    order_updated: store.ui_red.order_updated
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateOrders: (order_updated) => dispatch({ type: actionTypes.ORDERUPDATED, value: order_updated }),
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(EditUser);