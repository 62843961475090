import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Pagination } from 'semantic-ui-react';
import { Form, InputGroup, FormControl, Button } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';

import AUX from '../../../../hoc/Aux_';
import { doGet } from '../../../../helpers/ApiHelper';
import * as actionTypes from '../../../../store/action';
import ProductsTable from './Components/ProductsTable';
import DetailedProduct from './DetailedProduct';

class Products extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      currentTab: 'products',
      products: {
        current_page: 1,
        first_page: true,
        last_page: false,
        per_page: 20,
        total_count: 0,
        total_pages: 0,
        total_entries: 0,
        offset: 0,
        entries: []
      },
      term: '',
      sort: {
        column_sort: 'produtos.id',
        order: 'desc'
      },
      productId: null,
      productsTable: {},
      product_updated: false,
      last_updated_modified: ''
    };

    this.currentUser = JSON.parse(localStorage.getItem('user'));
  }

  componentDidMount() {
    this.getStatusProducts()
    this.loadProducts();
  }

  changeCurrentTab(tab) {
    window.scrollTo(0, 0);
    this.setState({ currentTab: tab });
  }

  showDetailedProduct = productId => {
    this.setState({ productId: productId, currentTab: 'detailed_product' });
  }

  loadProducts = (page = this.state.products.current_page, per_page = this.state.products.per_page, start_date = this.state.startDate, end_date = this.state.endDate) => {
    const term = this.state.term !== '' ? `&term=${this.state.term}` : '';
    if (this.state.products.per_page !== per_page) page = 1;

    this.setState({
      loading: true
    }, 
      () => doGet(`/products?page=${page}&per_page=${per_page}&column_sort=${this.state.sort.column_sort}&order=${this.state.sort.order}${term}`)
              .then(response => {
                  this.initialState(response);
                }
              )
    );
  }

  initialState = responseData => {
    this.setState({
      loading: false,
      products: responseData
    }, () => {
      if (responseData.out_of_bounds) this.loadProducts(1);
    });
  }

  handlePage = (e, { activePage }) => {
    this.loadProducts(
      activePage,
      this.state.products.per_page,
      this.state.startDate,
      this.state.endDate
    );
  }

  handlePerPageChange = option => {
    const per_page = parseInt(option.target.value);

    if (this.state.products.per_page === per_page) return;

    this.loadProducts(
      this.state.products.current_page,
      per_page,
      this.state.startDate,
      this.state.endDate
    );
  }

  handleDateRangePickerCallback = (start, end) => {
    this.setState({
      startDate: start.format('YYYY-MM-DD'),
      endDate: end.format('YYYY-MM-DD')
    });
  }

  handleApply = (ev, picker) => {
    const interval = picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY');
    this.setState({ initialValue: interval })
    picker.element.val(interval);
    this.loadProducts(
      this.state.products.current_page,
      this.state.products.per_page,
      this.state.startDate,
      this.state.endDate
    );
  }

  sortColumn = column => {
    const order = this.state.sort.order === 'asc' ? 'desc' : 'asc';
    this.setState({
      sort: {
        column_sort: column,
        order: this.state.sort.column_sort === column ? order : 'asc'
      }
    }, () => this.loadProducts(1));
  }

  handleSearchChange = option => {
    this.setState({ term: option.target.value });
  }

  getStatusProducts() {
    if (this.currentUser.superadmin_role || this.currentUser.owner_role) {
      doGet('/api_update/status?table=produtos').then(response => this.setState({
        product_updated: false,
        last_updated_modified: `Produtos atualizados em: ${response['modified']}`
      }));
    }
  }

  updateProducts() {
    doGet('/products/update_api')
      .then(response =>
        this.setState({
          product_updated: true,
          last_updated_modified: `Produtos atualizados em: ${response['api_update']['modified']}`
        }, () => this.loadProducts())
      )
      .catch(error => {
        this.setState({
          product_updated: false,
          last_updated_modified: `Ocorreu um erro na atualização, tente novamente.`
        }, () => this.loadProducts())
      });
  }

  render() {
    let productsContent = (<div className="mt-5 spinner"></div>);
    const perPageOptions = (
      <>
        <option value="20">Mostrar 20</option>
        <option value="50">Mostrar 50</option>
        <option value="100">Mostrar 100</option>
        <option value="150">Mostrar 150</option>
      </>
    );

    const countResultsTable = (
      <div className="row">
        <div className="col-md-12">
          Mostrando {this.state.products.offset + 1} para {this.state.products.last_page ? this.state.products.total_entries : this.state.products.current_page * this.state.products.total_count} de {this.state.products.total_entries} resultados
        </div>
      </div>
    );

    if (this.state.currentTab === 'products') {
      let updateProductsInfo = null;

      if (this.currentUser.superadmin_role || this.currentUser.owner_role) {
        updateProductsInfo = (
          <div className="col-md-4 offset-md-4 text-md-right">
            <div className="offset-md-4 text-center">
              <button type="button" className="btn btn-primary btn-sm" onClick={() => this.setState({ last_updated_modified: 'Atualizando...' }, this.updateProducts())}><i className="mdi mdi-refresh noti-icon"></i> Atualizar produtos</button>
              <br />
              <small><i>{this.state.last_updated_modified}</i></small>
            </div>
          </div>
        );
      }

      productsContent = (
        <React.Fragment>
          <div className="row mb-2">
            <div className="col-md-4">
              <InputGroup>
                <FormControl
                  placeholder="Buscar produto..."
                  defaultValue={this.state.term}
                  onChange={this.handleSearchChange.bind(this)}
                  onKeyPress={event => { if (event.key === 'Enter') this.loadProducts(1) }}
                />
                <InputGroup.Append>
                  <Button variant="primary" onClick={() => this.loadProducts(1)}><i className="mdi mdi-magnify"></i></Button>
                </InputGroup.Append>
              </InputGroup>
            </div>
            {updateProductsInfo}
          </div>

          <div className="row mb-2">
            <div className="col-md-auto" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
              <Form.Control size="sm" as="select" onChange={this.handlePerPageChange.bind(this)} defaultValue={this.state.products.per_page}>
                {perPageOptions}
              </Form.Control>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-auto">
              <h6>Listando {this.state.products.total_entries} produtos <small className="text-muted">Página {this.state.products.offset + 1} de {this.state.products.total_pages}</small></h6>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card m-b-20">
                <div className="card-body p-0">
                  <ProductsTable
                    loading={this.state.loading}
                    showDetailedProduct={this.showDetailedProduct.bind(this)}
                    productsEntries={this.state.products.entries}
                    sort={this.state.sort}
                    sortColumn={this.sortColumn.bind(this)}
                  />
                </div>
              </div>
              {countResultsTable}
              <div className="row text-center">
                <div className="col-md-12">
                  <Pagination
                    onPageChange={this.handlePage}
                    boundaryRange='1'
                    size='mini'
                    activePage={this.state.products.current_page}
                    totalPages={this.state.products.total_pages}
                  />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else if (this.state.currentTab === 'detailed_product') {
      productsContent = (
        <DetailedProduct
          changeCurrentTab={this.changeCurrentTab.bind(this)}
          previousTab={'products'}
          nameTab={'listagem de produtos'}
          productId={this.state.productId}
        />
      );
    }

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            {productsContent}
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = store => {
  return {
    order_updated: store.ui_red.order_updated
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateOrders: (order_updated) => dispatch({ type: actionTypes.ORDERUPDATED, value: order_updated }),
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Products);