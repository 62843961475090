import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import '../../../../assets/css/custom-daterangepicker.css';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../../../../assets/css/datatables.css';

import AUX from '../../../../hoc/Aux_';
import { doGet } from '../../../../helpers/ApiHelper';
import * as actionTypes from '../../../../store/action';

class OrdersPerState extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      orders: {
        entries: []
      },
      startDate: this.props.location.startDate === undefined ? moment().format('YYYY-MM-DD') : moment(this.props.location.startDate).format('YYYY-MM-DD'),
      endDate: this.props.location.endDate === undefined ? moment().format('YYYY-MM-DD') : moment(this.props.location.endDate).format('YYYY-MM-DD'),
      initialValue: (this.props.location.startDate === undefined && this.props.location.endDate === undefined) ? `${moment().format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}` : `${moment(this.props.location.startDate).format('DD/MM/YYYY')} - ${moment(this.props.location.endDate).format('DD/MM/YYYY')}`
    };
  }

  componentDidMount() {
    this.loadOrdersPerState();
  }

  loadOrdersPerState = (filters = true) => {
    const start_date = moment(this.state.startDate).format('YYYY-MM-DD');
    const end_date = moment(this.state.endDate).format('YYYY-MM-DD');

    let params = '';
    if (filters === true) params = `?start_date=${start_date}&end_date=${end_date}`;

    this.setState({
      loading: true,
      orders: { entries: [] }
    }, () => {
      doGet(`/orders/per_state${params}`).then(response => {
        this.setState({
          loading: false,
          orders: response
        })
      }).catch(() => this.setState({ loading: false, orders: { entries: [] } }));
    });
  }

  handleDateRangePickerCallback = (start, end) => {
    this.setState({
      startDate: start.format('YYYY-MM-DD'),
      endDate: end.format('YYYY-MM-DD')
    });
  }

  handleApply = (ev, picker) => {
    const interval = picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY');
    this.setState({ initialValue: interval })
    picker.element.val(interval);
    this.loadOrdersPerState();
  }

  render() {
    let ordersContent = (<div className="spinner"></div>);
    const dateRangePickerSettings = {
      autoUpdateInput: false,
      alwaysShowCalendars: true,
      showDropdowns: true,
      showCustomRangeLabel: false,
      opens: 'left',
      startDate: moment().toDate(),
      endDate: moment().toDate(),
      maxYear: parseInt(moment().format('Y')),
      ranges: {
        'Hoje': [moment().toDate(), moment().toDate()],
        'Ontem': [
          moment().subtract(1, 'days').toDate(),
          moment().subtract(1, 'days').toDate(),
        ],
        'Semana atual': [
          moment().startOf('week'),
          moment().endOf('week'),
        ],
        'Últimos 7 dias': [
          moment().subtract(6, 'days').toDate(),
          moment().toDate(),
        ],
        'Últimos 30 dias': [
          moment().subtract(29, 'days').toDate(),
          moment().toDate(),
        ],
        'Mês atual': [
          moment().startOf('month').toDate(),
          moment().endOf('month').toDate(),
        ],
        'Mês passado': [
          moment().subtract(1, 'month').startOf('month').toDate(),
          moment().subtract(1, 'month').endOf('month').toDate(),
        ],
      },
      locale: {
        applyLabel: 'Aplicar',
        cancelLabel: 'Cancelar',
        daysOfWeek: [
          'D',
          'S',
          'T',
          'Q',
          'Q',
          'S',
          'S'
        ]
      }
    }

    const { ExportCSVButton } = CSVExport;

    function priceFormatter(cell) {
      const cellValue = typeof cell === 'string' ? parseFloat(cell.replace('.', '').replace(',', '.')) : cell;

      return cellValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }

    const columns = [{
      dataField: 'uf',
      text: 'UF',
      sort: true,
      filter: textFilter({
        placeholder: 'Filtrar',
      }),
      footer: ''
    }, {
      dataField: 'qtd_pedidos',
      text: 'Qtd Pedidos',
      sort: true,
      filter: textFilter({
        placeholder: 'Filtrar',
      }),
      footer: columnData => columnData.reduce((acc, item) => acc + item, 0)
    }, {
      dataField: 'valor_total',
      text: 'Valor total',
      sort: true,
      formatter: priceFormatter,
      csvFormatter: priceFormatter,
      filter: textFilter({
        placeholder: 'Filtrar',
      }),
      footer: columnData => priceFormatter(columnData.reduce((acc, item) => { const itemValue = typeof item === 'string' ? parseFloat(item.replace('.', '').replace(',', '.')) : item; return acc + itemValue }, 0))
    }];

    const NoDataIndication = () => {
      return this.state.loading ? (<div className="py-4"><span className="spinner"></span></div>) : 'Nenhum resultado encontrado'
    }

    const ordersPerStateTable = (
      <ToolkitProvider
        bootstrap4
        keyField='id'
        data={this.state.orders.entries}
        columns={columns}
        exportCSV={{ fileName: 'pedidos_por_estado.csv', onlyExportFiltered: true, exportAll: false, noAutoBOM: false }}
        search
      >
        {
          props => (
            <>
              <BootstrapTable
                {...props.baseProps}
                filter={filterFactory()}
                pagination={
                  paginationFactory(
                    {
                      showTotal: true,
                      sizePerPageList: [
                        { text: '10', value: 10 },
                        { text: '20', value: 20 },
                        { text: '50', value: 50 },
                        { text: '100', value: 100 },
                      ],
                      paginationTotalRenderer: (from, to, size) => (
                        <span className="ml-2 react-bootstrap-table-pagination-total">
                          Mostrando {from} para {to} de {size} resultados
                        </span>
                      )
                    }
                  )
                }
                defaultSorted={
                  [{
                    dataField: 'qtd_pedidos',
                    order: 'desc'
                  }]
                }
                striped
                hover
                condensed
                noDataIndication={() => <NoDataIndication />}
                filterPosition="top"
                wrapperClasses="table-responsive"
              />
              <div className="row">
                <div className="col-12">
                  <ExportCSVButton className="btn btn-secondary float-right" {...props.csvProps}>Exportar CSV</ExportCSVButton>
                </div>
              </div>
            </>
          )
        }
      </ToolkitProvider>
    );

    ordersContent = (
      <>
        <div className="row mb-2">
          <div className="col-md-3">
            <DateRangePicker
              initialSettings={dateRangePickerSettings}
              onCallback={this.handleDateRangePickerCallback}
              onApply={this.handleApply}
            >
              <input type="text" className="form-control" value={this.state.initialValue} />
            </DateRangePicker>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card m-b-20">
              <div className="card-body">
                <div className="row mt-0 m-b-30" >
                  <div className="col-md-6">
                    <h4 className="header-title">Produtos mais vendidos</h4>
                  </div>
                </div>
                {ordersPerStateTable}
              </div>
            </div>
          </div>
        </div>
      </>
    );

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            {ordersContent}
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = store => {
  return {
    order_updated: store.ui_red.order_updated
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateOrders: (order_updated) => dispatch({ type: actionTypes.ORDERUPDATED, value: order_updated }),
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(OrdersPerState);