import React, { Component } from 'react';
import { connect } from 'react-redux';

import AUX from '../../../../hoc/Aux_';
import { doPost } from '../../../../helpers/ApiHelper';
import * as actionTypes from '../../../../store/action';

class AddUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 'add_user',
      newUser: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        superadmin_role: 'false',
        owner_role: 'false'
      }
    };
  }

  changeCurrentTab = tab => {
    window.scrollTo(0, 0);
    this.setState({ currentTab: tab });
  }

  handleNameChange = event => {
    const value = event.target.value;

    this.setState(prevState => ({ newUser: { ...prevState.newUser, name: value } }));
  }

  setSuperadminRole = event => {
    const value = event.target.value;
    if (this.state.newUser.superadmin_role === value) return;

    this.setState(prevState => ({ newUser: { ...prevState.newUser, superadmin_role: value } }));
  }

  setOwnerRole = event => {
    const value = event.target.value;
    if (this.state.newUser.owner_role === value) return;

    this.setState(prevState => ({ newUser: { ...prevState.newUser, owner_role: value } }));
  }

  handleEmailChange = event => {
    const value = event.target.value;

    this.setState(prevState => ({ newUser: { ...prevState.newUser, email: value } }));
  }

  handlePwdChange = event => {
    const value = event.target.value;

    this.setState(prevState => ({ newUser: { ...prevState.newUser, password: value } }));
  }

  handlePwdConfirmationChange = event => {
    const value = event.target.value;

    this.setState(prevState => ({ newUser: { ...prevState.newUser, password_confirmation: value } }));
  }

  handleSubmit = event => {
    event.preventDefault();

    if (this.state.selectedProduct !== null) {
      const params = {
        user: {
          name: this.state.newUser.name,
          email: this.state.newUser.email,
          password: this.state.newUser.password,
          password_confirmation: this.state.newUser.password_confirmation,
          superadmin_role: this.state.newUser.superadmin_role,
          owner_role: this.state.newUser.owner_role
        }
      }

      if (this.state.newUser.password !== this.state.newUser.password_confirmation) {
        alert("As senhas devem ser iguais.");
        return;
      }

      doPost(`/sign_up`, params).then(response => {
        if (response.status === 200) {
          alert(`Usuário ${this.state.newUser.name} - ${this.state.newUser.email} cadastrado com sucesso!`);
          this.props.changeCurrentTab('users');
        }
        else alert(`Erro ao cadastrar o usuário!`);
      });
    }
  }

  render() {
    return (
      <AUX>
        <button type="button" className="btn btn-link px-0" onClick={() => this.props.changeCurrentTab('users')}>Voltar para listagem de usuários</button>
        <div className="row">
          <div className="col-md-12">
            <div className="card m-b-20">
              <div className="card-body shadow-sm">
                <h4>Criar usuário</h4>
                <form className="row" onSubmit={this.handleSubmit.bind(this)}>
                  <div className="col-md-8 offset-md-2">
                    <div className="form-group">
                      <label htmlFor="name">Nome</label>
                      <input type="text" className="form-control" id="name" placeholder="Nome do usuário" value={this.state.newUser.name} onChange={this.handleNameChange.bind(this)} required />
                    </div>
                    <label htmlFor="superadmin_role">Superadmin</label>
                    <div className="form-group" onChange={this.setSuperadminRole.bind(this)}>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="superadminRadioOptions" id="superadmin_true" value="true" />
                        <label className="form-check-label" style={{ fontWeight: 'lighter' }} htmlFor="superadmin_true">Sim</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="superadminRadioOptions" id="superadmin_false" value="false" />
                        <label className="form-check-label" style={{ fontWeight: 'lighter' }} htmlFor="superadmin_false">Não</label>
                      </div>
                    </div>
                    <label htmlFor="owner_role">Owner</label>
                    <div className="form-group" onChange={this.setOwnerRole.bind(this)}>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="ownerRadioOptions" id="owner_true" value="true" />
                        <label className="form-check-label" style={{ fontWeight: 'lighter' }} htmlFor="owner_true">Sim</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="ownerRadioOptions" id="owner_false" value="false" />
                        <label className="form-check-label" style={{ fontWeight: 'lighter' }} htmlFor="owner_false">Não</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">E-mail</label>
                      <input type="email" className="form-control" id="email" placeholder="E-mail" value={this.state.newUser.email} onChange={this.handleEmailChange.bind(this)} required/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Senha</label>
                      <input type="password" className="form-control" id="password" value={this.state.newUser.password} onChange={this.handlePwdChange.bind(this)} required/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="password_confirmation">Confirmar senha</label>
                      <input type="password" className="form-control" id="password_confirmation" value={this.state.newUser.password_confirmation} onChange={this.handlePwdConfirmationChange.bind(this)} required/>
                    </div>
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary px-5 float-right">Cadastrar</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = store => {
  return {
    order_updated: store.ui_red.order_updated
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateOrders: (order_updated) => dispatch({ type: actionTypes.ORDERUPDATED, value: order_updated }),
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(AddUser);