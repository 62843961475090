export const doGet = path => {
  const url = `${process.env.REACT_APP_BACKEND_URL}${path}`
  return fetch(url, {
    mode: 'cors',
    cache: 'no-cache',
    redirect: 'follow',
    referrer: 'no-referrer',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  }).then(response => response.json());
}

export const doPost = (path, params) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}${path}`
  return fetch(url, {
    mode: 'cors',
    cache: 'no-cache',
    redirect: 'follow',
    referrer: 'no-referrer',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(params)
  }).then(response => response);
}

export const doPut = (path, params) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}${path}`
  return fetch(url, {
    mode: 'cors',
    cache: 'no-cache',
    redirect: 'follow',
    referrer: 'no-referrer',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    method: 'PUT',
    body: JSON.stringify(params)
  }).then(response => response);
}

export const doDelete = path => {
  const url = `${process.env.REACT_APP_BACKEND_URL}${path}`
  return fetch(url, {
    mode: 'cors',
    cache: 'no-cache',
    redirect: 'follow',
    referrer: 'no-referrer',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    method: 'DELETE'
  }).then(response => response);
}

export const doCheckUser = () => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/logged_in`
  return fetch(url, {
    mode: 'cors',
    cache: 'no-cache',
    redirect: 'follow',
    referrer: 'no-referrer',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  }).then(response => response);
}
