import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteForever } from '@material-ui/icons';
import MultiSelect from 'react-multi-select-component';

import AUX from '../../../../hoc/Aux_';
import { doGet, doPost, doPut, doDelete } from '../../../../helpers/ApiHelper';
import * as actionTypes from '../../../../store/action';

class DetailedProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product: null,
      productLoaded: false,
      productsSearchList: [],
      compositionProductsList: [],
      totalPrice: 0.0,
      selectedProduct: []
    };
  }

  componentDidMount() {
    doGet(`/products/${this.props.productId}`).then(response =>
      this.setState({ product: response }, () => this.setState({ productLoaded: true }))
    );

    this._getCompositionProductsList();
  }

  _getCompositionProductsList() {
    this.setState({ compositionProductsList: [], totalPrice: 0.0 }, () => {
      doGet(`/product_compositions/${this.props.productId}`).then(response => {
        let compositionProductsList = [];
        response['produtos'].forEach(compositionProduct => {
          compositionProductsList.push({
            id: compositionProduct.id,
            produto_id: compositionProduct.produto_id,
            quantidade: compositionProduct.quantidade,
            brinde: compositionProduct.brinde,
            nome: compositionProduct.nome,
            sku: compositionProduct.sku,
            valor_unitario: compositionProduct.valor_unitario,
            valor_venda: compositionProduct.valor_venda
          });
        });

        this.setState({ compositionProductsList: compositionProductsList, totalPrice: response['preco_cheio'] }, () => {
          let productIds = [this.props.productId];
          if (this.state.compositionProductsList.length > 0) this.state.compositionProductsList.map((compositionProduct) => productIds.push(compositionProduct.produto_id));

          doGet(`/products/list_products_composition?product_ids=${productIds}`).then(response => {
            let products = [];
            response.forEach(function (product) {
              products.push({
                value: product.id,
                label: `${product.nome} - SKU: ${product.sku}`
              });
            });

            this.setState({ productsSearchList: products });
          });
        });
      });
    });
  }

  _inputSelectChange = selectedProduct => {
    this.setState({ selectedProduct: Array.from(selectedProduct, item => item) });
  }

  _onCellChange = (index, dataType, value) => {
    const newState = this.state.compositionProductsList.map((item, i) => {
      if (i === index) return { ...item, [dataType]: value };

      return item;
    });

    this.setState({ compositionProductsList: newState });
  }

  _addProduct = () => {
    if (this.state.selectedProduct.length > 0) {
      const params = {
        produtos: this.state.selectedProduct.map(product => { return {
          produto_pai_id: this.props.productId,
          produto_id: product.value,
          quantidade: 1
        }})
      }

      doPost(`/product_compositions`, params).then(response => {
        if (response.status === 201) this.setState({ selectedProduct: [] }, () => this._getCompositionProductsList());
        else alert(`Erro ao adicionar o produto!`);
      });
    }
  }

  _removeProduct = productCompositionId => {
    doDelete(`/product_compositions/${productCompositionId}`).then(response => {
      if (response.status === 204) this._getCompositionProductsList();
      else alert(`Erro ao deletar o produto! productCompositionId: ${productCompositionId}`);
    });
  }

  _updateProducts = () => {
    let params = { "product_composition": [] }

    this.state.compositionProductsList.forEach(compositionProduct => {
      params['product_composition'].push({
        "produto_id": compositionProduct.produto_id,
        "quantidade": compositionProduct.quantidade
      })
    });

    doPut(`/product_compositions/${this.props.productId}`, params).then(response => {
      if (response.status === 200) this._getCompositionProductsList();
      else alert(`Erro ao atualizar o produto! productId: ${this.props.productId}`);
    });
  }

  _cancelChanges = () => {
    this.setState({ selectedProduct: [] }, () => {
      this._getCompositionProductsList();
    });
  }

  render() {
    let productContent = (<div className="spinner"></div>);
    let productCompositionContent = (<div className="spinner"></div>);

    if (this.state.productLoaded) {
      productContent = (
        <React.Fragment>
          <div className="form-group row">
            <label htmlFor="product-name" className="col-sm-2 col-form-label">Nome do produto</label>
            <div className="col-sm-10">
              <input className="form-control" type="text" id="product-name" value={this.state.product.nome} readOnly/>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="product-sku" className="col-sm-2 col-form-label">Código do produto (SKU)</label>
            <div className="col-sm-4">
              <input className="form-control" type="text" id="product-sku" value={this.state.product.sku} readOnly/>
            </div>
            <label htmlFor="product-variation" className="col-sm-2 col-form-label">Produto com variação?</label>
            <div className="col-sm-4">
              <input className="form-control" type="text" id="product-variation" value={this.state.product.produto_variacao} readOnly/>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="product-gtin" className="col-sm-2 col-form-label">GTIN</label>
            <div className="col-sm-4">
              <input className="form-control" type="text" id="product-gtin" value={this.state.product.gtin} readOnly/>
            </div>
            <label htmlFor="product-activate" className="col-sm-2 col-form-label">Produto ativado?</label>
            <div className="col-sm-4">
              <input className="form-control" type="text" id="product-activate" value={this.state.product.produto_ativado} readOnly/>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="product-mpn" className="col-sm-2 col-form-label">MPN</label>
            <div className="col-sm-4">
              <input className="form-control" type="text" id="product-mpn" value={this.state.product.mpn} readOnly/>
            </div>
            <label htmlFor="product-highlight" className="col-sm-2 col-form-label">Produto em destaque?</label>
            <div className="col-sm-4">
              <input className="form-control" type="text" id="product-highlight" value={this.state.product.produto_em_destaque} readOnly/>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="product-ncm" className="col-sm-2 col-form-label">NCM</label>
            <div className="col-sm-4">
              <input className="form-control" type="text" id="product-ncm" value={this.state.product.ncm} readOnly/>
            </div>
            <label htmlFor="product-condition" className="col-sm-2 col-form-label">Produto novo ou usado?</label>
            <div className="col-sm-4">
              <input className="form-control" type="text" id="product-condition" value={this.state.product.produto_condicao} readOnly/>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="product-cost" className="col-sm-2 col-form-label">Preço de custo</label>
            <div className="col-sm-4">
              <input className="form-control" type="text" id="product-cost" value={this.state.product.preco_custo.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} readOnly />
            </div>
            <label htmlFor="product-weight" className="col-sm-2 col-form-label">Peso</label>
            <div className="col-sm-4">
              <input className="form-control" type="text" id="product-weight" value={this.state.product.peso} readOnly />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="product-sell-price" className="col-sm-2 col-form-label">Preço de venda</label>
            <div className="col-sm-4">
              <input className="form-control" type="text" id="product-sell-price" value={this.state.product.preco_venda.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} readOnly />
            </div>
            <label htmlFor="product-height" className="col-sm-2 col-form-label">Altura</label>
            <div className="col-sm-4">
              <input className="form-control" type="text" id="product-height" value={this.state.product.altura} readOnly />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="product-promotional-price" className="col-sm-2 col-form-label">Preço promocional</label>
            <div className="col-sm-4">
              <input className="form-control" type="text" id="product-promotional-price" value={this.state.product.preco_promocional.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} readOnly />
            </div>
            <label htmlFor="product-width" className="col-sm-2 col-form-label">Largura</label>
            <div className="col-sm-4">
              <input className="form-control" type="text" id="product-width" value={this.state.product.largura} readOnly />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label htmlFor="product-category" className="col-sm-2 col-form-label">Categoria</label>
            <div className="col-sm-4">
              <input className="form-control" type="text" id="product-category" value={this.state.product.categorias} readOnly />
            </div>
            <label htmlFor="product-brand" className="col-sm-2 col-form-label">Marca</label>
            <div className="col-sm-4">
              <input className="form-control" type="text" id="product-brand" value={this.state.product.marca} readOnly />
            </div>
          </div>
          <br />
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Descrição</label>
            <div className="col-sm-10">
              <div className="overflow-auto p-2" style={{ maxHeight: '225px', backgroundColor: '#e9ecef', color: '#495057', border: '1px solid #ced4da', borderRadius: '.25rem' }}>
                <p dangerouslySetInnerHTML={{ __html: this.state.product.descricao }} />
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="product-tag-title" className="col-sm-2 col-form-label">Tag Title</label>
            <div className="col-sm-10">
              <input className="form-control" type="text" id="product-tag-title" value={this.state.product.tag_title} readOnly />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="product-meta-tag-description" className="col-sm-2 col-form-label">Meta Tag Description</label>
            <div className="col-sm-10">
              <input className="form-control" type="text" id="product-meta-tag-description" value={this.state.product.meta_tag_description} readOnly />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="product-meta-tag-keywords" className="col-sm-2 col-form-label">Meta Tag Keywords</label>
            <div className="col-sm-10">
              <input className="form-control" type="text" id="product-meta-tag-keywords" value={this.state.product.meta_tag_keywords} readOnly />
            </div>
          </div>
        </React.Fragment>
      );

      productCompositionContent = (
        <React.Fragment>
          <div className="row mt-0 m-b-30">
            <label className="w-100" htmlFor="searchProduct">Vincular produtos</label>
            <MultiSelect
              className="w-100"
              options={this.state.productsSearchList}
              value={this.state.selectedProduct}
              onChange={this._inputSelectChange}
              labelledBy={"Pesquise por nome ou sku..."}
              hasSelectAll={false}
              overrideStrings={{
                "selectSomeItems": "Pesquise por nome ou sku...",
                "search": "Pesquisar",
                "clearSearch": "Limpar pesquisa"
              }}
            />
            <button type="submit" className="btn btn-primary" onClick={() => this._addProduct()}>Adicionar</button>
          </div>
          <div className="row m-b-30">
            <table className="table table-striped table-bordered table-hover table-sm">
              <thead className="thead-light">
                <tr>
                  <th scope="col" style={{ width: '10%' }}>Quantidade</th>
                  <th scope="col">Valor unitário</th>
                  <th scope="col"></th>
                  <th scope="col">Subtotal</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.compositionProductsList.map((compositionProduct, index) => {
                    const sellValue = compositionProduct.valor_venda.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
                    const sellValueText = compositionProduct.brinde ? (<><span style={{ textDecoration: 'line-through' }}> {sellValue}</span><br /><strong>Brinde</strong></>) : sellValue;
                    return (
                      <tr key={index}>
                        <td className="text-center"><input type="text" className="form-control" value={this.state.compositionProductsList[index].quantidade} onChange={(e) => this._onCellChange(index, 'quantidade', e.target.value)} /></td>
                        <td>{compositionProduct.valor_unitario.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                        <td>
                          {compositionProduct.nome}
                          <br />
                          <small>SKU: {compositionProduct.sku}</small>
                        </td>
                        <td>{sellValueText}</td>
                        <td className="text-center"><button type="button" className="btn text-danger" onClick={() => { if (window.confirm(`Você deseja excluir o produto ${compositionProduct.nome}?`)) this._removeProduct(compositionProduct.id) }} ><i><DeleteForever /></i></button></td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
            <h6 className="w-100 text-right">Preço cheio: {this.state.totalPrice.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h6>
          </div>
          <hr />
          <div className="row m-b-30">
            <button type="button" className="btn btn-primary" onClick={() => this._updateProducts()}>Atualizar</button>
            <button type="button" className="btn btn-link" onClick={() => this._cancelChanges()}>Cancelar</button>
          </div>
        </React.Fragment>
      );
    }

    return (
      <AUX>
        <button type="button" className="btn btn-link px-0" onClick={() => this.props.changeCurrentTab(this.props.previousTab)}>Voltar para {this.props.nameTab}</button>
        <div className="row">
          <div className="col-md-12">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" id="product-tab" data-toggle="tab" href="#product" role="tab" aria-controls="product" aria-selected="true">Detalhes do produto</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="composition-tab" data-toggle="tab" href="#composition" role="tab" aria-controls="composition" aria-selected="false">Composição</a>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="product" role="tabpanel" aria-labelledby="product-tab">
                <div className="card m-b-20">
                  <div className="card-body shadow-sm">
                    {productContent}
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="composition" role="tabpanel" aria-labelledby="composition-tab">
                <div className="card m-b-20">
                  <div className="card-body shadow-sm">
                    {productCompositionContent}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = store => {
  return {
    order_updated: store.ui_red.order_updated
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateOrders: (order_updated) => dispatch({ type: actionTypes.ORDERUPDATED, value: order_updated }),
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(DetailedProduct);